<template>
  <form class="caixa-form" @submit="submit">
    <div class="row4">
      <div class="item">
        <label> Placa:<span>*</span> </label>
        <input
          type="text"
          id="placa"
          :value="veiculo.placa"
          @input="(event) => updateValue('placa', event.target.value)"
        />
      </div>
      <div class="item">
        <label> Modelo:<span>*</span> </label>
        <input
          type="text"
          :value="veiculo.modelo"
          @input="(event) => updateValue('modelo', event.target.value)"
          id="modelo"
        />
      </div>
      <div class="item">
        <label> Tipo de Combustível:<span>*</span> </label>
        <select
          @change="
            (event) => updateValue('tipo_combustivel', event.target.value)
          "
        >
          <option disabled :value="null" :selected="!veiculo.tipo_combustivel">
            Selecione
          </option>
          <option
            v-for="tipo in tiposCombustivel"
            :key="tipo.value"
            :value="tipo.value"
            :selected="tipo.value === veiculo.tipo_combustivel"
          >
            {{ tipo.label }}
          </option>
        </select>
      </div>
      <div class="item">
        <label> Tipo de Veículo:</label>
        <TipoVeiculoAutoComplete v-model="veiculo.tipo_veiculo" />
      </div>
    </div>
    <div class="row4">
      <div class="item">
        <label> Marca:</label>
        <MarcaAutoComplete v-model="veiculo.marca" />
      </div>
      <div class="item">
        <label> Data vencimento do IPVA: </label>
        <DatePicker
          v-model="dataVencimentoIpva"
          @change="(value) => updateValue('data_vencimento_ipva', value)"
          format="DD/MM/YYYY"
        />
      </div>
      <div class="item">
        <label> Ano do Veículo:</label>
        <input
          type="text"
          :value="veiculo.ano_veiculo"
          @input="(event) => updateValue('ano_veiculo', event.target.value)"
          id="ano_veiculo"
        />
      </div>
      <div class="item">
        <div class="checkbox">
          <label class="check path" for="veiculo_proprio">
            <input
              type="checkbox"
              name="veiculo_proprio"
              :value="veiculo.veiculo_proprio"
              :checked="veiculo.veiculo_proprio"
              @change="
                (event) => {
                  updateValueCheckbox(event);
                }
              "
              id="veiculo_proprio"
              :true-value="true"
              :false-value="false"
            />

            <svg viewBox="0 0 21 21">
              <path
                d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"
              ></path>
            </svg>
            <span>Veículo Próprio?</span>
          </label>
        </div>
      </div>
    </div>
    <div>
      <BotaoSalvar v-if="!salvando" />
      <AppLoading v-else size="small" texto="" />
    </div>
  </form>
</template>
<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import AppLoading from "../../components/AppLoading";
import { parseISO } from "date-fns";
import TipoVeiculoAutoComplete from "../autocompletes/TipoVeiculoAutoComplete";
import MarcaAutoComplete from "../autocompletes/MarcaAutoComplete";
import BotaoSalvar from "../botoes/BotaoSalvar";

export default {
  name: "VeiculosFormulario",
  components: {
    DatePicker,
    AppLoading,
    TipoVeiculoAutoComplete,
    MarcaAutoComplete,
    BotaoSalvar,
  },
  model: {
    prop: "veiculo",
    event: "update-veiculo",
  },
  props: {
    veiculo: {
      type: Object,
      required: true,
    },
    tiposCombustivel: {
      type: Array,
      required: true,
    },
    salvando: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataVencimentoIpva:
        this.veiculo.data_vencimento_ipva &&
        typeof this.veiculo.data_vencimento_ipva == "string"
          ? parseISO(this.veiculo.data_vencimento_ipva)
          : this.veiculo.data_vencimento_ipva,
    };
  },
  watch: {
    dataVencimentoIpva: function (newValue) {
      this.dataVencimentoIpva = newValue;
    },
  },
  methods: {
    submit(e) {
      this.$emit("submit", e);
    },
    updateValue(key, value) {
      this.$emit("update-veiculo", {
        ...this.veiculo,
        [key]: value,
      });
    },
    updateValueCheckbox(event) {
      const { checked, name } = event.srcElement;
      this.updateValue(name, checked);
    },
  },
};
</script>
<style lang="scss" scoped>
.caixa-form {
  background-color: white;
  padding: 1rem;
  margin-bottom: 3rem;
  .item {
    display: grid;
    gap: 0.5rem;
    margin: 1rem 0;
    label {
      font-family: "Proxima-Regular";
      margin-bottom: 0.2rem;
    }
    span {
      color: red;
    }
    input {
      height: 1.5rem;
      width: 100%;
      font-size: 1rem;
      width: calc(100% - 0.5rem);
    }
    select {
      height: 2rem;
      font-size: 1rem;
    }
    .mx-datepicker {
      width: 100%;
    }

    .checkbox {
      margin-top: 1rem;
      input {
        width: auto;
      }
      span {
        color: black;
        font-family: "Proxima-SemiBold";
      }
      .check {
        --background: #fff;
        --border: #d1d6ee;
        --border-hover: #bbc1e1;
        --border-active: #1b5138;
        --tick: #fff;
        position: relative;
        cursor: pointer;
        input,
        svg {
          width: 21px;
          height: 21px;
          display: inline-block;
        }
        input {
          -webkit-appearance: none;
          -moz-appearance: none;
          position: relative;
          outline: none;
          background: var(--background);
          border: none;
          margin: 0;
          transform: translateY(5px);
          padding: 0;
          cursor: pointer;
          border-radius: 4px;
          transition: box-shadow 0.3s;
          box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));
          &:hover {
            --s: 2px;
            --b: var(--border-hover);
          }
          &:checked {
            --b: var(--border-active);
          }
        }
        svg {
          pointer-events: none;
          fill: none;
          stroke-width: 2px;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke: var(--stroke, var(--border-active));
          position: absolute;
          top: 0;
          left: 0;
          width: 21px;
          height: 21px;
          transform: scale(var(--scale, 1)) translateZ(1);
          margin-top: -0.2rem;
        }
        span {
          display: inline;
          margin-left: 1rem;
        }
        &.path {
          input {
            &:checked {
              --s: 2px;
              transition-delay: 0.4s;
              & + svg {
                --a: 16.1 86.12;
                --o: 102.22;
              }
            }
          }
          svg {
            stroke-dasharray: var(--a, 86.12);
            stroke-dashoffset: var(--o, 86.12);
            transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
          }
        }
      }
    }
  }
}
@media screen and (min-width: "50em") {
  .row4 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1rem;
    align-items: center;
  }
}
</style>