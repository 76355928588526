import axios from "axios";
import axiosAuthenticated from "./AxiosAutenticated";

class ConfiguracoesApi {
  cancelTokens = {
    getConfiguracao: null,
    alterarConfiguracao: null,
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async getConfiguracao() {
    const CancelTokens = axios.CancelToken;
    const source = CancelTokens.source();

    this.cancelTokens.getConfiguracao = source;

    const {
      data
    } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/configuracoes/mostrar`, {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getConfiguracao = null;
    return data;
  }

  async alterarConfiguracao(configuracao) {
    try {
      const CancelTokens = axios.CancelToken;
      const source = CancelTokens.source();

      this.cancelTokens.alterarConfiguracao = source;

      const {
        data
      } = await axiosAuthenticated.put;
      (`${process.env.VUE_APP_BASE_API_URL}/api/v1/configuracoes/alterar`, configuracao, {
        cancelToken: source.token,
      });

      this.cancelTokens.alterarConfiguracao = null;
      return data;
    } catch (err) {
      this.cancelTokens.alterarConfiguracao = null;
      throw err;
    }
  }
}

export default ConfiguracoesApi;