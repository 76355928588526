<template>
  <div class="grupo-calendar">
    <ViagensResultadosCalendarioHeader
      v-on="$listeners"
      :ano-referente="anoReferente"
      :scroll-y="scrollY"
      :top="375"
      :mes-referente="mesReferente"
    />
    <div class="mes">
      <div class="semana" v-for="nSemana in semanasMes" :key="nSemana">
        <div
          :class="{
            'fim-de-semana': isFimDeSemana(diaDoMes(nSemana, nDia)),
            'dia-atual': isDiaAtual(diaDoMes(nSemana, nDia)),
          }"
          class="dia"
          v-for="nDia in 7"
          :key="nDia"
        >
          <template
            v-if="
              // verificando os períodos fora do mês atual do calendário
              !(
                (nSemana == 1 && nDia < semanaInicio) ||
                (nSemana == semanasMes && nDia > semanaFim)
              )
            "
          >
            <div
              title="Criar uma nova Viagem"
              @click.self="chamarNew"
              class="header-dia"
            >
              <h2>{{ diaDoMes(nSemana, nDia) }}</h2>
              <span
                class="numero-viagens-dia"
                v-if="viagensDoDia(diaDoMes(nSemana, nDia))"
                >{{
                  viagensDoDia(diaDoMes(nSemana, nDia)).viagens_do_dia.length
                }}
                {{
                  viagensDoDia(diaDoMes(nSemana, nDia)).viagens_do_dia.length ==
                  1
                    ? "Viagem"
                    : "Viagens"
                }}</span
              >
            </div>
            <ViagensResultadosCalendarioViagensDia
              @chamar-show="chamarShow"
              v-if="viagensDoDia(diaDoMes(nSemana, nDia))"
              :viagem="viagensDoDia(diaDoMes(nSemana, nDia))"
              :mesReferente="mesReferente"
              :anoReferente="anoReferente"
              :isDiaAtual="isDiaAtual(diaDoMes(nSemana, nDia))"
              :valorDeCorte="3"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getWeeksInMonth, getDay, isWeekend } from "date-fns";
import ViagensResultadosCalendarioViagensDia from "../../components/viagens/ViagensResultadosCalendarioViagensDia.vue";
import ViagensResultadosCalendarioHeader from "../../components/viagens/ViagensResultadosCalendarioHeader.vue";
import { VIAGEM_NEW, VIAGEM_SHOW } from "../../router/routeNames";

export default {
  name: "ViagensResultadosCalendario",
  props: {
    mesReferente: { type: Number, required: true },
    anoReferente: { type: Number, required: true },
    viagens: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      VIAGEM_NEW,
      VIAGEM_SHOW,
      semanasMes: getWeeksInMonth(
        new Date(this.anoReferente, this.mesReferente, 1)
      ),
      inicioMes: new Date(this.anoReferente, this.mesReferente, 1),
      fimMes: new Date(this.anoReferente, this.mesReferente + 1, 0),
      semanaInicio:
        getDay(new Date(this.anoReferente, this.mesReferente, 1)) + 1,
      semanaFim:
        getDay(new Date(this.anoReferente, this.mesReferente + 1, 0)) + 1,
      scrollY: null,
    };
  },
  mounted() {
    window.addEventListener("scroll", () => {
      this.scrollY = Math.round(window.scrollY);
    });
  },
  computed: {},
  components: {
    ViagensResultadosCalendarioViagensDia,
    ViagensResultadosCalendarioHeader,
  },
  methods: {
    isFimDeSemana(dia) {
      return isWeekend(new Date(this.anoReferente, this.mesReferente, dia));
    },
    isDiaAtual(dia) {
      let diaAtual = new Date();
      return (
        diaAtual.getDate() == dia && diaAtual.getMonth() == this.mesReferente
      );
    },

    chamarShow(id) {
      this.$router.push({ name: VIAGEM_SHOW, params: { id: id } });
    },
    chamarNew() {
      this.$router.push({ name: VIAGEM_NEW });
    },
    viagensDoDia(dia) {
      let viagensDoDia = null;

      viagensDoDia = this.viagens.filter((viagensDia) => {
        if (viagensDia.dia_agendamento_previsto == dia) return viagensDia;
      });

      return viagensDoDia[0];
    },
    diaDoMes(nSemana, nDia) {
      return (nSemana - 1) * 7 + (nDia - this.semanaInicio + 1);
    },
  },
};
</script>
<style lang="scss" scoped>
.grupo-calendar {
  background-color: white;
  .mes {
    display: grid;
    margin-bottom: 5rem;
    gap: 2rem 0;
    padding: 0 1rem 1rem 1rem;
    .semana {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      gap: 1rem;
      .dia {
        border-top: solid black 2px;
        min-height: 6rem;
        .header-dia {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0.5rem 0.5rem 0.5rem;
          gap: 1rem;
          h2 {
            margin: 0;
          }
          span {
            font-size: 0.7rem;
            font-family: "Proxima-SemiBold";
            color: grey;
          }
        }
      }
      .fim-de-semana {
        border-top: silver solid 2px;
        .header-dia {
          h2 {
            color: silver;
          }
        }
      }
      .dia-atual {
        background-image: linear-gradient(to bottom, #01440317, transparent);
        border-top: #014403 solid 2px;
        .header-dia {
          h2 {
            color: #014403;
          }
        }
      }
    }
  }
}
</style>