<template>
  <div class="sem-permissao">
    <img src="../assets/images/alert-error.png" alt="">
    <h1>
      Usuário sem permissão!
    </h1>
  </div>
</template>
<script>
export default {
  name: "SemPermissao",
  metaInfo: {
    title: "Sem permissão",
    titleTemplate: "%s - Frotas",
  },
};
</script>
<style lang="scss" scoped>
.sem-permissao{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -8rem;
  height: 100vh;
  img{
    max-width: 10rem;
    width: 90%;
  }
  h1{
    color: black;
    font-family: 'Proxima-Bold';
    text-align: center;
    font-size: 1.5rem;
  }
}
</style>
