<template>
  <div class="teste" :style="myStyle">
    <span class="data-id">Goiânia-GO, {{ diaAtual }}</span>
    <div class="botoes">
      <button
        type="button"
        title="Mês anterior"
        @click="emitirAlerarCalendario(anoReferente, mesReferente - 1, 1)"
      >
        &#60;
      </button>
      <button
        class="voltar-mes"
        type="button"
        title="Voltar ao mês atual"
        @click="emitirAlerarCalendario(null, null, null)"
      >
        <h1>{{ mesReferenteFormatado }}</h1>
      </button>
      <button
        type="button"
        title="Próximo mês"
        @click="emitirAlerarCalendario(anoReferente, mesReferente + 1, 1)"
      >
        &#62;
      </button>
    </div>
    <div class="semana header-calendario">
      <span class="semana-fim-header">Dom</span>
      <span>Seg</span>
      <span>Ter</span>
      <span>Qua</span>
      <span>Qui</span>
      <span>Sex</span>
      <span class="semana-fim-header">Sab</span>
    </div>
  </div>
</template>
<script>
import { format } from "date-fns";
import pt from "date-fns/locale/pt";

export default {
  name: "ViagensResultadosCalendarioHeader",
  props: {
    mesReferente: { type: Number, required: true },
    anoReferente: { type: Number, required: true },
    top: { type: Number },
    scrollY: { type: Number },
  },
  data() {
    return {
      diaAtual: format(new Date(), "dd 'de' MMMM 'de' yyyy", { locale: pt }),
      mesReferenteFormatado: this.capitalize(
        format(new Date(this.anoReferente, this.mesReferente, 1), "MMMM yyyy", {
          locale: pt,
        })
      ),
      myStyle: {},
      originalTop: 0,
    };
  },
  mounted() {
    this.originalTop = window.innerHeight;
  },
  computed: {},
  watch: {
    scrollY() {
      const newTop = this.scrollY + +this.top - this.originalTop;

      if (newTop > 0) {
        this.$set(this.myStyle, "top", `${newTop}px`);
      } else {
        this.$delete(this.myStyle, "top");
      }
    },
  },
  components: {},
  methods: {
    emitirAlerarCalendario(anoReferente, mesReferente, diaReferente) {
      if (anoReferente) {
        this.$emit(
          "alterar-calendario",
          new Date(anoReferente, mesReferente, diaReferente)
        );
      } else {
        this.$emit("alterar-calendario", new Date());
      }
    },
    capitalize(txt) {
      return txt.charAt(0).toUpperCase() + txt.slice(1);
    },
  },
};
</script>
<style lang="scss" scoped>
.teste {
  background-color: white;
  position: relative;
  margin-bottom: -0.1rem;
  .data-id {
    padding: 1rem 1rem 0 1rem;
    display: block;
  }
  .botoes {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 2rem 0;
    button {
      font-size: 1.5rem;
      border: none;
      margin: 0 0.5rem;
      font-family: "Proxima-bold";
      background-color: transparent;
      padding: 0.5rem;
      cursor: pointer;
      transition: 0.2s;
      h1 {
        font-size: 2rem;
        font-family: "proxima-bold";
        margin: 0;
      }
    }
    button:hover {
      background-color: #efefef;
    }
    .voltar-mes {
      border-bottom: none;
      font-size: 0.5rem;
      margin: 0;
    }
  }
  .semana {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 1rem;
    background-color: #6b6b6b;
  }
  .header-calendario {
    padding: 1rem;
    span {
      text-transform: uppercase;
      font-family: "Proxima-semibold";
      font-size: 0.8rem;
      color: white;
    }
    .semana-fim-header {
      color: rgb(206, 206, 206);
    }
  }
}
</style>