<template>
  <div id="teste" class="centro">
    <div class="index-header">
      <h1>Viagens</h1>
      <BotaoNovoIndex :to="{ name: VIAGEM_NEW }" />
    </div>
    <AppLoading v-if="carregando" />
    <AppOverlayLoading v-if="excluindo">
      <span>Excluindo... Por favor, aguarde.</span>
    </AppOverlayLoading>
    <AppErro v-if="erroCarregando && !carregando" />
    <ViagemPesquisaForm
      v-model="filtro"
      :veiculos="veiculos"
      :motoristas="motoristas"
      @submit.prevent="buscar"
      v-if="!carregando && !erroCarregando"
    />
    <div v-if="!carregando && !erroCarregando">
      <div class="header-resultados">
        <h4>Resultados</h4>
        <div>
          <label>Modo de Exibição: </label>
          <select v-model="isCalendario">
            <option :value="true">Calendário</option>
            <option :value="false">Lista</option>
          </select>
        </div>
      </div>
      <template v-if="!isCalendario">
        <div class="topo-2-resultados">
          <AppTotalResultadosEncontrados :total-resultados="totalResultados" />

          <AppResultadosPorPagina
            :per-page="perPage"
            @per-page-changed="perPageChanged"
          />
        </div>
        <ViagensResultadosLista
          :viagens="viagens"
          @excluir-viagem="excluirViagem"
        />
        <div class="paginacao">
          <Paginate
            v-model="page"
            :page-count="pageCount"
            :page-renge="5"
            prev-text="Anterior"
            next-text="Próxima"
            :clickHandler="mudarPagina"
          ></Paginate>
        </div>
      </template>
      <template v-else>
        <!-- //props -->
        <ViagensResultadosCalendario
          :viagens="viagens"
          :mesReferente="mesFiltroCalendario"
          :anoReferente="anoFiltroCalendario"
          @alterar-calendario="alterarCalendario"
      /></template>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import Paginate from "vuejs-paginate";
import BotaoNovoIndex from "../../components/botoes/BotaoNovoIndex";
import ViagensResultadosCalendario from "../../components/viagens/ViagensResultadosCalendario";
import AppLoading from "../../components/AppLoading";
import AppErro from "../../components/AppErro";
import AppOverlayLoading from "../../components/AppOverlayLoading";
import AppResultadosPorPagina from "../../components/AppResultadosPorPagina";
import AppTotalResultadosEncontrados from "../../components/AppTotalResultadosEncontrados";
import { DEFAULT_PER_PAGE } from "../../config";
import { VIAGEM_NEW, VIAGENS, VIAGEM_EDIT } from "../../router/routeNames";
import ViagemApi from "../../services/api/ViagemApi";
import ViagensResultadosLista from "../../components/viagens/ViagensResultadosLista";
import ViagemPesquisaForm from "../../components/viagens/ViagemPesquisaForm";
import filterNullsAndEmptyStrings from "../../helpers/filterNullsAndEmptyStrings";

const isEqual = require("lodash.isequal");
const isEmpty = require("lodash.isempty");

export default {
  name: "ViagemIndex",
  metaInfo: {
    title: "Viagens",
    titleTemplate: "%s - Frotas",
  },
  components: {
    Paginate,
    AppLoading,
    AppOverlayLoading,
    BotaoNovoIndex,
    AppResultadosPorPagina,
    AppErro,
    AppTotalResultadosEncontrados,
    ViagensResultadosCalendario,
    ViagensResultadosLista,
    ViagemPesquisaForm,
  },
  data() {
    return {
      VIAGEM_NEW,
      VIAGENS,
      VIAGEM_EDIT,
      DEFAULT_PER_PAGE,
      viagens: [],
      motoristas: [],
      veiculos: [],
      totalResultados: 0,
      isCalendario: this.$route.query.isCalendario || true,
      carregando: false,
      erroCarregando: false,
      anoFiltroCalendario: null,
      mesFiltroCalendario: null,
      carregandoFiltro: false,
      excluindo: false,
      page: this.$route.query.page ? parseInt(this.$route.query.page) : 1,
      perPage: this.$route.query.per_page || DEFAULT_PER_PAGE,
      filtro: {
        motorista_id: this.$route.query.motorista_id || null,
        veiculo_id: this.$route.query.veiculo_id || null,
        setor_solicitante: this.$route.query.setor_solicitante || "",
        data_agendamento_previsto_inicio:
          this.$route.query.data_agendamento_previsto_inicio || null,
        data_agendamento_previsto_fim:
          this.$route.query.data_agendamento_previsto_fim || null,
      },
    };
  },
  created() {
    this.viagemApi = new ViagemApi();
    const t = new Date();
    this.mesFiltroCalendario = t.getMonth();
    this.anoFiltroCalendario = t.getFullYear();
    this.carregarDados();
  },
  watch: {
    isCalendario() {
      if (this.isCalendario) {
        this.$router.push({
          isCalendario: true,
        });
      } else {
        this.buscar();
      }
    },
    $route: function (to, from) {
      if (to.name == VIAGENS && !isEqual(to.query, from.query)) {
        this.carregarDados();
      }
    },
  },
  methods: {
    limparFiltro() {
      const chaves = Object.keys(this.filtro);
      this.filtro = chaves.reduce((acc, chave) => {
        acc[chave] = null;
        return acc;
      }, {});
    },
    async alterarCalendario(d) {
      try {
        this.mesFiltroCalendario = d.getMonth();
        this.anoFiltroCalendario = d.getFullYear();

        this.carregando = true;
        this.erroCarregando = false;

        //o range do mes no js é 0 a 11, por isso o +1
        const dataViagem = await this.viagemApi.getViagemPorAnoMes(
          this.mesFiltroCalendario + 1,
          this.anoFiltroCalendario
        );
        this.viagens = dataViagem;

        this.$scrollTo("#teste", {
          offset: -200,
        });

        this.carregando = false;
        this.carregandoFiltro = false;
      } catch (err) {
        console.log(err);
        this.erroCarregando = true;
        this.carregandoFiltro = false;
        this.carregando = false;
      }
    },
    async carregarDados() {
      try {
        this.carregando = true;
        this.erroCarregando = false;

        if (isEmpty(this.$route.query) || this.$route.query.diaRef) {
          this.isCalendario = true;
        } else {
          this.isCalendario = false;
        }

        this.filtro = {
          motorista_id: this.$route.query.motorista_id || null,
          veiculo_id: this.$route.query.veiculo_id || null,
          setor_solicitante: this.$route.query.setor_solicitante || "",
          data_agendamento_previsto_inicio:
            this.$route.query.data_agendamento_previsto_inicio || null,
          data_agendamento_previsto_fim:
            this.$route.query.data_agendamento_previsto_fim || null,
        };

        // viagens
        if (this.isCalendario) {
          // pegar mes e ano do calendârio, via evento.
          //o range do mes no js é 0 a 11, por isso o +1
          const dataViagem = await this.viagemApi.getViagemPorAnoMes(
            this.mesFiltroCalendario + 1,
            this.anoFiltroCalendario
          );
          this.viagens = dataViagem;
        } else {
          const dataViagem = await this.viagemApi.getviagens({
            ...this.$route.query,
            page: this.$route.query.page || this.page,
            perPage: this.$route.query.perPage || this.perPage,
          });

          this.viagens = dataViagem.resultados;
          this.totalResultados = dataViagem.total_resultados;
        }

        // motoristas
        const dataMotorista = await this.viagemApi.getMotoristas(
          this.$route.params.id
        );

        //veiculos
        const dataVeiculo = await this.viagemApi.getVeiculos(
          this.$route.params.id
        );

        this.motoristas = dataMotorista;
        this.veiculos = dataVeiculo;

        this.carregando = false;
        this.carregandoFiltro = false;
      } catch (err) {
        console.log(err);
        this.erroCarregando = true;
        this.carregandoFiltro = false;
        this.carregando = false;
      }
    },
    async excluirViagem(id) {
      try {
        const result = await this.$swal.fire({
          title: "Você tem certeza?",
          text: "Você não poderá desfazer essa ação",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        });

        if (!result.isConfirmed) {
          return;
        }

        this.excluindo = true;

        await this.viagemApi.excluirViagem(id);
        this.excluindo = false;

        this.$swal({
          icon: "success",
          position: "top-end",
          toast: true,
          title: "Viagem excluído com sucesso!",
          timer: 3000,
          showConfirmButton: false,
        });

        if (this.viagens.length == 1 && this.page > 1) {
          this.page--;
          this.isCalendario = false;
          this.$router.push({
            ...this.$route.query,
            perPage: this.$route.query.perPage || this.perPage,
            isCalendario: this.isCalendario,
            page: this.page,
          });
        } else {
          this.carregarDados();
        }
      } catch (err) {
        console.log(err);
        this.excluindo = false;
        this.$swal({
          icon: "error",
          title:
            "Não foi possível excluir o registro. Por favor tente novamente.",
          showConfirmButton: true,
        });
      }
    },
    perPageChanged(newValuePerPage) {
      if (newValuePerPage < this.perPage) {
        this.perPage = newValuePerPage;
        this.mudarPagina(this.page);
      } else {
        this.perPage = newValuePerPage;
        this.mudarPagina(1);
      }
    },
    mudarPagina(pageNum) {
      this.page = pageNum;
      this.$router.push({
        name: VIAGENS,
        query: {
          ...this.$route.query,
          page: this.page,
          isCalendario: this.isCalendario,
          per_page: this.perPage,
        },
      });
    },
    buscar() {
      this.page = 1;
      this.isCalendario = false;

      // tive que transformar o datetime in string pq por algum motivo ao passar
      // para o query, ele estava mandando um {} e dando erro.,
      this.filtro.data_agendamento_previsto_inicio = this.filtro
        .data_agendamento_previsto_inicio
        ? this.filtro.data_agendamento_previsto_inicio.toString()
        : null;

      this.filtro.data_agendamento_previsto_fim = this.filtro
        .data_agendamento_previsto_fim
        ? this.filtro.data_agendamento_previsto_fim.toString()
        : null;

      this.$router.push({
        query: filterNullsAndEmptyStrings({
          ...this.filtro,
          page: this.page,
          isCalendario: this.isCalendario,
          per_page: this.perPage,
        }),
      });
    },
  },
  computed: {
    pageCount() {
      if (
        this.totalResultados != undefined &&
        this.totalResultados != null &&
        this.totalResultados > 0
      ) {
        return Math.ceil(this.totalResultados / this.perPage);
      }
      return 0;
    },
  },
};
</script>
<style lang="scss" scoped>
.index-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  gap: 1rem;
  h1 {
    font-family: "Proxima-Bold";
    color: #014403;
    margin: 0;
  }
}
.header-resultados,
.topo-2-resultados {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 0.5rem;
  h4 {
    font-family: "Proxima-Bold";
    margin: 1rem 0;
    color: #014403;
    font-size: 1.4rem;
  }
  select {
    height: 2rem;
    font-size: 1rem;
    svg {
      background: red;
      height: 1rem;
      width: 1rem;
    }
  }
}
.topo-2-resultados {
  .numeros-resultados {
    margin-bottom: 0;
  }
}
</style>