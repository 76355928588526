import * as Auth from "../services/Auth";

import privateRoutes from "../router/rotas_privadas";

const intersection = require("lodash.intersection");

//retorna a rota inicial de um usuario de acordo com as permissoes que ele tem
//retorna null se usuario nao estiver logado
export default function getRotaInicial() {
    if (!Auth.isLoggedIn()) {
        return null;
    }

    return privateRoutes.find(
        (rota) =>
        rota.meta.requerPermissao &&
        rota.meta.raiz &&
        intersection(rota.meta.permissoes, Auth.getUsuario().funcionalidades)
        .length > 0
    );
}