<template>
  <div class="centro" >
    <div class="header-show">
      <div class="esquerda">
        <router-link :to="{ name: VIAGENS, query: { ...$route.query } }">
          <IconVoltar />
        </router-link>
      <h1>Viagem</h1>
      </div>
      <BotaoEditarShow
        :to="{ name: VIAGEM_EDIT, params: { id: $route.params.id } }"
      />
    </div>
    <template>
      <AppLoading v-if="carregando && !erroCarregando" />
      <AppErro v-if="erroCarregando && !carregando" />
    </template>
    <div v-if="!carregando && !erroCarregando">
      <ViagemShowDadosGerais :viagem="viagem" />
    </div>
  </div>
</template>
<script>
import AppErro from "../../components/AppErro";
import AppLoading from "../../components/AppLoading";
import IconVoltar from "../../assets/images/icon-voltar.svg";
import BotaoEditarShow from "../../components/botoes/BotaoEditarShow";
import ViagemShowDadosGerais from "../../components/viagens/ViagemShowDadosGerais";
import { VIAGEM_EDIT, VIAGENS } from "../../router/routeNames";
import ViagemApi from "../../services/api/ViagemApi";

export default {
  name: "ViagemShow",
  metaInfo: {
    title: "Exibir Viagem",
    titleTemplate: "%s - Frotas",
  },
  components: {
    BotaoEditarShow,
    AppLoading,
    AppErro,
    ViagemShowDadosGerais,
    IconVoltar,
  },
  data() {
    return {
      viagem: null,
      VIAGEM_EDIT,
      VIAGENS,
      carregando: false,
      erroCarregando: false,
    };
  },
  created() {
    this.viagemApi = new ViagemApi();
    this.carregarViagem();
  },
  methods: {
    async carregarViagem() {
      try {
        this.erroCarregando = false;
        this.carregando = true;

        this.viagem = await this.viagemApi.getViagem(this.$route.params.id);
        this.carregando = false;
      } catch (e) {
        console.log(e);
        this.carregando = false;
        this.erroCarregando = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.header-show {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  .esquerda {
    display: flex;
    align-items: center;
    gap: 1rem;
  h1 {
    font-family: "Proxima-Bold";
    color: #014403;
    margin: 0;
  }
  }
}
</style>