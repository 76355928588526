import rotasMotoristas from "./motoristas";
import rotasMarcas from "./marcas";
import rotasVeiculos from "./veiculos";
import rotasTiposVeiculos from "./tipos_veiculo"
import rotasViagens from "./viagens"

// a ordem no array indica a ordem de prioridade da rota para redirecionamento ao logar
const rotasPrivadas = [
    ...rotasViagens,
    ...rotasMarcas,
    ...rotasTiposVeiculos,
    ...rotasMotoristas,
    ...rotasVeiculos
];

export default rotasPrivadas