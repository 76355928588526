import axios from "axios";
import axiosAuthenticated from "./AxiosAutenticated";

const queryString = require("query-string");

class MarcasApi {
  cancelTokens = {
    getMarcas: null,
    getMarca: null,
    salvarMarca: null,
    excluirMarca: null,
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async getMarcas({ page, per_page, nome }) {
    const CancelTokens = axios.CancelToken;
    const source = CancelTokens.source();

    this.cancelTokens.getMarcas = source;

    const searchString = queryString.stringify({
      page,
      per_page,
      nome,
    });

    const { data } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/marcas?${searchString}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getMarcas = null;

    return data;
  }

  async getMarca(id) {
    const CancelTokens = axios.CancelToken;
    const source = CancelTokens.source();

    this.cancelTokens.getMarca = source;

    const { data } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/marcas/${id}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getMarca = null;
    return data;
  }

  async salvarMarca(marca) {
    try {
      const CancelTokens = axios.CancelToken;
      const source = CancelTokens.source();

      this.cancelTokens.salvarMarca = source;

      let method;
      let url;

      if (marca.id) {
        method = axiosAuthenticated.put;
        url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/marcas/${marca.id}`;
      } else {
        method = axiosAuthenticated.post;
        url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/marcas`;
      }
      const { data } = await method(url, marca, {
        cancelToken: source.token,
      });

      this.cancelTokens.salvarMarca = null;
      return data;
    } catch (err) {
      this.cancelTokens.salvarMarca = null;
      throw err;
    }
  }

  async excluirMarca(id) {
    const CancelTokens = axios.CancelToken;
    const source = CancelTokens.source();

    this.cancelTokens.excluirMarca = source;
    const { data } = await axiosAuthenticated.delete(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/marcas/${id}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.excluirMarca = null;
    return data;
  }
}

export default MarcasApi;
