<template>
  <div>
    <form class="caixa-pesquisa" @submit="submit">
      <h4>Pesquisar</h4>
      <div>
        <div class="row3">
          <div class="item">
            <label>Motorista:</label>
            <select
              @change="
                (event) => filtroMudou('motorista_id', event.target.value)
              "
            >
              <option :value="null" :selected="!filtro.motorista_id">
                Selecione
              </option>
              <option
                v-for="motorista in motoristas"
                :key="motorista.value"
                :value="motorista.value"
                :selected="motorista.value == filtro.motorista_id"
              >
                {{ motorista.label }}
              </option>
            </select>
          </div>
          <div class="item">
            <label>Veículo:</label>
            <select
              @change="(event) => filtroMudou('veiculo_id', event.target.value)"
            >
              <option :value="null" :selected="!filtro.veiculo_id">
                Selecione
              </option>
              <option
                v-for="veiculo in veiculos"
                :key="veiculo.value"
                :value="veiculo.value"
                :selected="veiculo.value == filtro.veiculo_id"
              >
                {{ veiculo.label }}
              </option>
            </select>
          </div>
          <div class="item">
            <label>Setor Solicitante:</label>
            <input
              type="text"
              @input="
                (event) => filtroMudou('setor_solicitante', event.target.value)
              "
              :value="filtro.setor_solicitante"
            />
          </div>
        </div>
        <div class="item datas">
          <label>Período de Viagem:</label>
          <div class="disp-flex">
            <DatePicker
              v-model="dataAgendamentoPrevistoInicio"
              @change="
                (value) =>
                  filtroMudou('data_agendamento_previsto_inicio', value)
              "
              format="DD/MM/YYYY H:mm"
              confirm
              type="datetime"
            />
            <label>a</label>
            <DatePicker
              v-model="dataAgendamentoPrevistoFim"
              @change="
                (value) => filtroMudou('data_agendamento_previsto_fim', value)
              "
              format="DD/MM/YYYY H:mm"
              confirm
              type="datetime"
            />
          </div>
        </div>
      </div>
      <div class="botoes-pesquisa">
        <button class="bt-limpar" type="button" @click="limpar">
          <IconLimpar /> Limpar
        </button>
        <button class="bt-pesquisar" type="submit">
          <IconPesquisar />Pesquisar
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import IconPesquisar from "../../assets/images/icon-pesquisar.svg";
import IconLimpar from "../../assets/images/icon-clean.svg";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "ViagemPesquisaForm",
  components: {
    IconPesquisar,
    IconLimpar,
    DatePicker,
  },
  model: {
    prop: "filtro",
    event: "update-filtro-pesquisa-viagem",
  },
  props: {
    filtro: {
      type: Object,
    },
    veiculos: {
      type: Array,
      required: true,
    },
    motoristas: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dataAgendamentoPrevistoInicio:
        this.filtro.data_agendamento_previsto_inicio &&
        typeof this.filtro.data_agendamento_previsto_inicio == "string"
          ? new Date(Date.parse(this.filtro.data_agendamento_previsto_inicio))
          : this.filtro.data_agendamento_previsto_inicio,
      dataAgendamentoPrevistoFim:
        this.filtro.data_agendamento_previsto_fim &&
        typeof this.filtro.data_agendamento_previsto_fim == "string"
          ? new Date(Date.parse(this.filtro.data_agendamento_previsto_fim))
          : this.filtro.data_agendamento_previsto_fim,
    };
  },
  methods: {
    submit(event) {
      this.$emit("submit", event);
    },
    filtroMudou(chave, valor) {
      this.$emit("update-filtro-pesquisa-viagem", {
        ...this.filtro,
        [chave]: valor,
      });
    },
    limpar() {
      const chaves = Object.keys(this.filtro);
      const filtroLimpo = chaves.reduce((acc, chave) => {
        acc[chave] = null;
        return acc;
      }, {});
      this.dataAgendamentoPrevistoInicio = null;
      this.dataAgendamentoPrevistoFim = null;
      this.$emit("update-filtro-pesquisa-viagem", filtroLimpo);
    },
  },
  watch: {
    dataAgendamentoPrevistoInicio: function (newValue) {
      this.dataAgendamentoPrevistoInicio = newValue;
    },
    dataAgendamentoPrevistoFim: function (newValue) {
      this.dataAgendamentoPrevistoFim = newValue;
    },
  },
};
</script>
<style lang="scss" scoped>
.caixa-pesquisa {
  background-color: white;
  padding: 1rem;
  h4 {
    margin: 0 0 0.5rem;
    font-family: "Proxima-Bold";
    color: #014403;
    font-size: 1.4rem;
  }
  .item {
    display: grid;
    margin: 1rem 0;
    gap: 0.5rem;
    input {
      height: 1.5rem;
      font-size: 1rem;
    }
    select {
      height: 2rem;
      font-size: 1rem;
      width: 100%;
    }
  }
  .disp-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0.2rem;
    label {
      margin: 0.3rem;
    }
  }
  .datas {
    border: solid 0.1rem rgba(0, 0, 0, 0.527);
    padding: 0.5rem;
    margin: 0 auto 1rem;
    border-radius: 0.3rem;
    max-width: 35rem;
  }
  .botoes-pesquisa {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .bt-limpar {
      color: black;
      font-size: 1rem;
      background-color: transparent;
      font-family: "Proxima-Semibold";
      border: none;
      margin: 0 2rem;
      cursor: pointer;
      transition: 0.2s;
      svg {
        height: 1rem;
        width: 1rem;
        margin-right: 0.5rem;
      }
    }
    .bt-pesquisar {
      display: flex;
      border: #014403 solid 0.15rem;
      padding: 0.5rem 2rem;
      border-radius: 1.2rem;
      color: #014403;
      font-family: "Proxima-Semibold";
      text-decoration: none;
      transition: 0.2s linear;
      font-size: 1rem;
      cursor: pointer;
      background-color: transparent;
      svg {
        height: 1rem;
        width: 1rem;
        margin-right: 0.5rem;
      }
    }
    .bt-pesquisar:hover {
      background-color: #014403;
      color: white;
    }
  }
}
@media screen and(min-width: '35em') {
  .disp-flex {
    flex-direction: row !important;
    justify-content: center;
  }
}
@media screen and(min-width: '50em') {
  .row3 {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    gap: 1rem;
  }
}
</style>