import * as routeNames from "./routeNames";

import IconAgendamento from "../assets/images/icon-agendamentos.svg";
import IconMotorista from "../assets/images/icon-motoristas.svg";
import IconVeiculos from "../assets/images/icon-veiculos.svg";
import IconCadastros from "../assets/images/icon-cadastros.svg";

const menu = [{
        label: "Agendamento de Viagens",
        routeName: routeNames.VIAGENS,
        icon: IconAgendamento
    },
    {
        label: "Veículos",
        routeName: routeNames.VEICULOS,
        icon: IconVeiculos
    },
    {
        label: "Motoristas",
        routeName: routeNames.MOTORISTAS,
        icon: IconMotorista
    },
    {
        label: "Cadastros",
        icon: IconCadastros,
        routeList: [{
                label: "Marcas",
                routeName: routeNames.MARCAS,
            },
            {
                label: "Tipos de Veículo",
                routeName: routeNames.TIPOS_VEICULO,
            },
        ],
    },
];

export default menu;