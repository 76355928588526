<template>
  <div class="bk-login">
    <div class="logos-login">
      <img
        src="../assets/images/logo-frotas1.png"
        class="logo-frotas"
        alt="Logo do sistema Frotas"
      />
    </div>
    <div class="caixa-login">
      <h1>Login</h1>
      <div class="text-red" v-show="erro">
        {{ this.erroMsg }}
      </div>
      <form @submit.prevent="doLogin">
        <div class="item">
          <label for="usuario">Usuário:</label>
          <div class="input">
            <IconLogin />
            <input type="text" v-model="login" id="usuario" />
          </div>
        </div>
        <div class="item">
          <label for="password">Senha:</label>
          <div class="input">
            <IconSenha />
            <input type="password" v-model="password" id="password" />
          </div>
        </div>
        <div class="bt-entrar">
          <AppLoading size="small" v-if="loading" texto="" />
          <button type="submit" :disabled="loading" v-else>
            <span>Entrar</span><IconEntrar />
          </button>
        </div>
      </form>
    </div>
    <div class="logos-login-mobile">
    </div>
  </div>
</template>

<script>
import AppLoading from "../components/AppLoading";
import * as Auth from "../services/Auth";
import getRotaInicial from "../helpers/getRotaInicial";
import IconEntrar from "../assets/images/icon-seta.svg";
import IconLogin from "../assets/images/icon-login.svg";
import IconSenha from "../assets/images/icon-senha.svg";

export default {
  name: "Login",
  metaInfo: {
    title: "Entrar",
    titleTemplate: "%s - Frotas",
  },
  data() {
    return {
      login: "",
      password: "",
      loading: false,
      erro: false,
      erroMsg: "",
    };
  },
  components: {
    AppLoading,
    IconEntrar,
    IconLogin,
    IconSenha,
  },
  methods: {
    async doLogin() {
      try {
        this.erro = false;
        this.erroMsg = "";
        this.loading = true;
        await Auth.login(this.login, this.password);
        this.loading = false;

        this.$router.push({ name: getRotaInicial().name });
      } catch (e) {
        console.log(e);
        this.loading = false;
        this.erro = true;
        this.erroMsg =
          e || "Houve um erro ao efetuar login. Por favor, tente novamente.";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bk-login {
  background-image: url("../assets/images/bk-login.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-top: -8rem;
  .logos-login {
    text-align: center;
    .logo-sic {
      display: none;
    }
  }
  .logo-frotas {
    width: 80%;
    max-width: 30rem;
  }
  .logo-sic {
    width: 50%;
    max-width: 15rem;
  }
  .caixa-login {
    background-color: white;
    border-radius: 0.2rem;
    box-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.548);
    padding: 2rem 1rem;
    width: 80%;
    max-width: 20rem;
    margin: 0 auto;
    h1 {
      font-family: "Proxima-Bold";
      color: #014403;
      margin: 0;
    }
    .text-red {
      color: red;
      margin-top: 1rem;
    }
    form {
      .item {
        display: grid;
        margin: 1rem 0;
        gap: 0.5rem;
        .input {
          display: flex;
          align-items: center;
          border: solid 0.15rem #ccc;
          border-radius: 0.12rem;
          height: 1.7rem;
          svg {
            height: 1.8rem;
            width: 1rem;
            background-color: #ccc;
            padding: 0 0.3rem;
          }
        }
        input {
          height: 1.5rem;
          background-color: transparent !important;
          width: 100%;
          border: none;
          padding-left: 0.5rem;
          font-size: 1rem;
        }
        input:focus {
          outline: none;
        }
      }
      .bt-entrar {
        text-align: right;
        margin-top: 2rem;
        button {
          border: solid 0.15rem #08730b;
          width: 9rem;
          height: 2rem;
          border-radius: 1rem;
          position: relative;
          cursor: pointer;
          transition: 0.2s;
          background-color: transparent;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-left: auto;
          span {
            color: #08730b;
            font-family: "proxima-SemiBold";
            font-size: 1rem;
            margin-right: 1.7rem;
          }
        }
        button:hover {
          background-color: #08730b;
          span {
            color: white;
            transition: 0.2s;
          }
        }
      }
    }
  }
  .logos-login-mobile {
    text-align: center;
    width: 100%;
  }
}
@media screen and(min-width: 60em) {
  .bk-login {
    flex-direction: row;
    justify-content: space-between;
    .caixa-login {
      margin: 0;
      border-radius: 0;
      height: 100%;
      padding: 0 2rem;
      max-width: 25rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h1 {
        margin-top: -4rem;
      }
    }
    .logos-login {
      margin-top: 5rem;
      width: 100%;
      .logo-sic {
        display: block;
        margin: 5rem auto;
        width: 10rem;
      }
    }
    .logos-login-mobile {
      display: none;
    }
  }
}
</style>
