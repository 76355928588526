<template>
  <div class="modal-form">
    <div class="caixa-form">
      <div class="parte1">
        <router-link :to="{ name: MARCAS, query: { ...$route.query } }"
          >✖</router-link
        >
        <h4>{{ $route.params.id ? "Editar" : "Nova" }} Marca</h4>
      </div>
      <div v-if="carregandoMarca && !erroCarregarMarca">
        <AppLoading />
      </div>
      <AppErrosFormularios
        v-if="erroSalvar && erroSalvar.length > 0"
        :erros="erroSalvar"
      />
      <form
        @submit.prevent="salvar"
        v-if="!carregandoMarca && !erroCarregarMarca"
      >
        <div class="item">
          <label> Nome: <span>*</span> </label>
          <input type="text" v-model="marcaForm.nome" id="numero" />
        </div>
        <div class="botao-salvar">
          <BotaoSalvar v-if="!salvando" />
          <AppLoading v-else size="small" texto="" />
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import MarcaApi from "../../services/api/MarcasApi";
import AppLoading from "../../components/AppLoading";
import AppErrosFormularios from "../../components/AppErrosFormularios";
import { MARCAS } from "../../router/routeNames";
import BotaoSalvar from "../../components/botoes/BotaoSalvar";

export default {
  name: "MarcaForm",
  components: {
    AppLoading,
    BotaoSalvar,
    AppErrosFormularios,
  },
  metaInfo() {
    return {
      title: this.$route.params.id ? "Editar Marca" : "Nova Marca",
      titleTemplate: "%s - Frotas",
    };
  },
  data() {
    return {
      MARCAS,
      marcaForm: { id: null, nome: "" },
      carregandoMarca: false,
      salvando: false,
      erroCarregarMarca: false,
      erroSalvar: null,
    };
  },
  created() {
    this.marcaApi = new MarcaApi();
    if (this.$route.params.id) {
      this.carregarMarca();
    }
  },
  methods: {
    async salvar() {
      try {
        this.salvando = true;
        this.erroSalvar = null;

        await this.marcaApi.salvarMarca({
          id: this.marcaForm.id,
          nome: this.marcaForm.nome,
        });

        this.$emit("on-reload-data");
        this.salvando = false;

        this.$swal({
          icon: "success",
          position: "top-end",
          title: "Marca salva com sucesso!",
          timer: 33000,
          toast: true,
          showConfirmButton: false,
        });

        this.$emit("finalizou-edicao");
        this.$router.push({
          name: MARCAS,
        });
      } catch (err) {
        this.salvando = false;

        let data;
        if (err.response) {
          data = err.response.data;
        }

        if (data) {
          if (Array.isArray(data)) {
            this.erroSalvar = data;
          } else if (data.mensagem) {
            this.erroSalvar = [data.mensagem];
          } else {
            this.erroSalvar = [
              "Houve um erro ao salvar os dados. Por favor, tente novamente",
            ];
          }
        } else {
          this.erroSalvar = [
            "Houve um erro ao salvar os dados. Por favor, tente novamente",
          ];
        }
      }
    },
    async carregarMarca() {
      try {
        this.carregandoMarca = true;
        this.erroCarregarMarca = false;

        const data = await this.marcaApi.getMarca(this.$route.params.id);

        this.marcaForm = data;

        this.carregandoMarca = false;
      } catch (err) {
        console.log(err);
        this.carregandoMarca = false;
        this.erroCarregarMarca = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.swal2-container {
  z-index: 5005;
}
.modal-form {
  position: fixed;
  z-index: 2001;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.426);
  display: flex;
  justify-content: center;
  align-items: center;
  .caixa-form {
    background-color: white;
    padding: 1rem;
    width: 80%;
    max-width: 25rem;
    position: relative;

    a {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      text-decoration: none;
      color: slategray;
      transition: 0.2s;
    }
    a:hover {
      color: red;
    }
    h4 {
      margin: 0 0 0.5rem;
      font-family: "Proxima-Bold";
      color: #014403;
      font-size: 1.4rem;
    }
    .item {
      display: grid;
      margin-top: 1rem;
      gap: 0.5rem;
      label {
        font-family: "Proxima-Regular";
      }
      span {
        color: red;
      }
      input {
        height: 1.5rem;
        font-size: 1rem;
      }
    }
    .botao-salvar {
      margin-top: 1rem;
    }
  }
}
</style>