import axios from "axios";
import axiosAuthenticated from "./AxiosAutenticated";

const queryString = require("query-string");

class TiposVeiculoApi {
    cancelTokens = {
        getTiposVeiculo: null,
        getTipoVeiculo: null,
        salvarTipoVeiculo: null,
        excluirTipoVeiculo: null
    };

    isCancel(exceptionThrown){
        return axios.isCancel(exceptionThrown);
    }

    async getTiposVeiculo({ page, per_page, nome }){
        const CancelTokens = axios.CancelToken;
        const source = CancelTokens.source();

        this.cancelTokens.getTiposVeiculo = source;

        const searchString = queryString.stringify({
            page,
            per_page,
            nome
        });

        const { data } = await axiosAuthenticated.get(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/tipos_veiculo?${searchString}`,
            {
                cancelToken: source.token,
            }
        );

        this.cancelTokens.getTiposVeiculo = null;

        return data;
    }

    async getTipoVeiculo(id){
        const CancelTokens = axios.CancelToken;
        const source = CancelTokens.source();

        this.cancelTokens.getTipoVeiculo = source;

        const { data } = await axiosAuthenticated.get(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/tipos_veiculo/${id}`,
            {
                cancelToken: source.token
            }
        );

        this.cancelTokens.getTipoVeiculo = null;
        return data;
    }

    async salvarTipoVeiculo(tipo_veiculo){
        try{
            const CancelTokens = axios.CancelToken;
            const source = CancelTokens.source();
    
            this.cancelTokens.salvarTipoVeiculo = source;
            let method;
            let url;
    
            if (tipo_veiculo.id){
                method = axiosAuthenticated.put;
                url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/tipos_veiculo/${tipo_veiculo.id}`
            }
            else{
                method = axiosAuthenticated.post;
                url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/tipos_veiculo`
            }
            const { data } = await method(url, tipo_veiculo, { cancelToken: source.token });
    
            this.cancelTokens.salvarTipoVeiculo = null;
            return data;
        } catch(err){
            this.cancelTokens.salvarTipoVeiculo = null;
            throw err;
        }
    }

    async excluirTipoVeiculo(id){
        const CancelTokens = axios.CancelToken;
        const source = CancelTokens.source();

        this.cancelTokens.excluirTipoVeiculo = source;

        const { data } = await axiosAuthenticated.delete(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/tipos_veiculo/${id}`,
            {
                cancelToken: source.token
            }
        );

        this.cancelTokens.excluirTipoVeiculo = null;
        return data;
    }
}

export default TiposVeiculoApi;