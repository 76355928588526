<template>
  <div>
    <router-link :to="to" title="Exibir"><IconVisualizar /> </router-link>
  </div>
</template>

<script>
import IconVisualizar from "../../../assets/images/icon-view.svg";
export default {
  name: "BotaoExibirTabela",
  components: {
    IconVisualizar,
  },
  props: {
    to: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
a{
  text-decoration: none;
  svg{
    height: 1.4rem;
    width: 1.4rem;
  }
}
</style>