<template>
  <VueSimpleAutocomplete
    :items="tiposVeiculo"
    :selected-item="tipoVeiculo"
    @change="searchTiposVeiculo"
    @value-selected="onTipoVeiculoSelected"
    :is-loading="loadingTipoVeiculo"
    :get-display-value="(tipo) => tipo.nome"
    :min-length="2"
    placeholder="Digite para pesquisar"
  ></VueSimpleAutocomplete>
</template>

<script>
import VueSimpleAutocomplete from "vue-simple-autocomplete";
import TiposVeiculoApi from "../../services/api/TiposVeiculoApi";

export default {
  name: "TipoVeiculoAutoComplete",
  components: {
    VueSimpleAutocomplete,
  },
  model: {
    prop: "tipoVeiculo",
    event: "change",
  },
  props: {
    tipoVeiculo: Object,
  },
  data() {
    return {
      tiposVeiculo: [],
      loadingTipoVeiculo: false,
    };
  },
  created() {
    this.tiposVeiculoApi = new TiposVeiculoApi();
  },
  methods: {
    async searchTiposVeiculo(term) {
      try {
        if (this.tiposVeiculoApi.cancelTokens.getTiposVeiculo) {
          this.tiposVeiculoApi.cancelTokens.getTiposVeiculo.cancel(
            "Usuário fez outra busca"
          );
        }

        this.tiposVeiculo = [];
        this.$emit("change", {});

        if (!term || term.length === 0) {
          return;
        }
        this.loadingTipoVeiculo = true;
        const data = await this.tiposVeiculoApi.getTiposVeiculo({ nome: term });

        this.tiposVeiculo = data.resultados;

        this.loadingTipoVeiculo = false;
      } catch (err) {
        if (!this.tiposVeiculoApi.isCancel(err)) {
          this.loadingTipoVeiculo = false;
        }
      }
    },
    onTipoVeiculoSelected(tipo) {
      this.$emit("change", tipo);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
