<template>
  <div>
    <button @click="click" title="Deletar"><IconDelete /></button>
  </div>
</template>
<script>
import IconDelete from "../../../assets/images/icon-delete.svg";

export default {
  name: "BotaoDeletarTabela",
  components:{
    IconDelete,
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>
<style lang="scss" scoped>
button{
  border: none;
  background-color: transparent;
  cursor: pointer;
  svg{
    height: 1.4rem;
    width: 1.4rem;
    fill: rgb(196, 0, 0);
  }
}
</style>