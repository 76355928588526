<template>
  <div class="modal-form">
    <div class="caixa-form">
      <router-link :to="{ name: TIPOS_VEICULO, query: { ...$route.query } }">
        ✖
      </router-link>
      <h4>{{ $route.params.id ? "Editar" : "Novo" }} tipo de veículo</h4>
      <div v-if="carregandoTipoVeiculo && !erroCarregarTipoVeiculo">
        <AppLoading />
      </div>
      <AppErrosFormularios
        v-if="erroSalvar && erroSalvar.length > 0"
        :erros="erroSalvar"
      />
      <form
        @submit.prevent="salvar"
        v-if="!carregandoTipoVeiculo && !erroCarregarTipoVeiculo"
      >
        <div class="item">
          <label>Nome: <span>*</span></label>
          <input type="text" v-model="TipoVeiculoForm.nome" id="numero" />
        </div>
        <div class="botao-salvar">
          <BotaoSalvar v-if="!salvando" />
          <AppLoading v-else size="small" texto="" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import AppLoading from "../../components/AppLoading";
import { TIPOS_VEICULO } from "../../router/routeNames";
import TiposVeiculoApi from "../../services/api/TiposVeiculoApi";
import BotaoSalvar from "../../components/botoes/BotaoSalvar";
import AppErrosFormularios from "../../components/AppErrosFormularios";

export default {
  name: "TipoVeiculoForm",
  metaInfo() {
    return {
      title: this.$route.params.id
        ? "Editar tipo de veículo"
        : "Novo tipo de veículo",
      titleTemplate: "%s - Frotas",
    };
  },
  components: {
    AppLoading,
    BotaoSalvar,
    AppErrosFormularios,
  },
  data() {
    return {
      TIPOS_VEICULO,
      TipoVeiculoForm: { id: null, nome: "" },
      carregandoTipoVeiculo: false,
      salvando: false,
      erroCarregarTipoVeiculo: false,
      erroSalvar: null,
    };
  },
  created() {
    this.TiposVeiculoApi = new TiposVeiculoApi();
    if (this.$route.params.id) {
      this.carregarTipoVeiculo();
    }
  },
  methods: {
    async salvar() {
      try {
        this.salvando = true;
        this.erroSalvar = null;

        await this.TiposVeiculoApi.salvarTipoVeiculo({
          id: this.TipoVeiculoForm.id,
          nome: this.TipoVeiculoForm.nome,
        });

        this.$emit("on-reload-data");
        this.salvando = false;

        this.$swal({
          icon: "success",
          toast: true,
          position: "top-end",
          title: "Tipo de Veículo salvo com sucesso!",
          timer: 3000,
          showConfirmButton: false,
        });

        this.$emit("finalizou-edicao");
        this.$router.push({ name: TIPOS_VEICULO });
      } catch (err) {
        this.salvando = false;

        let data;
        if (err.response) {
          data = err.response.data;
        }

        if (data) {
          if (Array.isArray(data)) {
            this.erroSalvar = data;
          } else if (data.mensagem) {
            this.erroSalvar = [data.mensagem];
          } else {
            this.erroSalvar = [
              "Houve um erro ao salvar os dados. Por favor, tente novamente",
            ];
          }
        } else {
          this.erroSalvar = [
            "Houve um erro ao salvar os dados. Por favor, tente novamente",
          ];
        }
      }
    },
    async carregarTipoVeiculo() {
      try {
        this.carregandoTipoVeiculo = true;
        this.erroCarregarTipoVeiculo = false;

        const data = await this.TiposVeiculoApi.getTipoVeiculo(
          this.$route.params.id
        );

        this.TipoVeiculoForm = data;

        this.carregandoTipoVeiculo = false;
      } catch (err) {
        console.log(err);
        this.carregandoTipoVeiculo = false;
        this.erroCarregarTipoVeiculo = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-form {
  position: fixed;
  z-index: 2001;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.426);
  display: flex;
  justify-content: center;
  align-items: center;
  .caixa-form {
    background-color: white;
    padding: 1rem;
    width: 80%;
    max-width: 25rem;
    position: relative;

    a {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      text-decoration: none;
      color: slategray;
      transition: 0.2s;
    }
    a:hover {
      color: red;
    }
    h4 {
      margin: 0 0 0.5rem;
      font-family: "Proxima-Bold";
      color: #014403;
      font-size: 1.4rem;
    }
    .text-red {
      color: red;
    }
    .item {
      display: grid;
      margin-top: 1rem;
      gap: 0.5rem;
      label {
        font-family: "Proxima-Regular";
      }
      span {
        color: red;
      }
      input {
        height: 1.5rem;
        font-size: 1rem;
      }
    }
    .botao-salvar {
      margin-top: 1rem;
    }
  }
}
</style>