<template>
  <router-link :to="to"><IconView /> Exibir </router-link>
</template>

<script>
import IconView from "../../assets/images/icon-view.svg";

export default {
  name: "BotaoVisualizarIndex",
  components:{
    IconView,
  },
  props: {
    to: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
a{
  display: flex;
  border: #014403 solid .15rem;
  padding: .5rem 2rem;
  border-radius: .2rem;
  color: #014403;
  font-family: 'Proxima-Semibold';
  text-decoration: none;
  transition: .2s linear;
  svg{
    height: 1rem;
    width: 1rem;
    margin-right: .5rem;
    fill: #ff820c;
  }
}
a:hover{
  background-color: #014403;
  color: white;
}
</style>