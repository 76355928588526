<template>
  <div>
    <router-link :to="to" title="Editar"><IconEditar /></router-link>
  </div>
</template>
<script>
import IconEditar from "../../../assets/images/icon-edit.svg";

export default {
  name: "BotaoEditarTabela",
  components: {
    IconEditar,
  },
  props: {
    to: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
a{
  text-decoration: none;
  svg{
    height: 1.4rem;
    width: 1.4rem;
  }
}
</style>