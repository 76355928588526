<template>
  <div id="app">
    <TheMenu v-if="exibirMenu"></TheMenu>
    <div class="app-conteudo">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";
import * as RouteNames from "./router/routeNames";
import TheMenu from "./components/TheMenu";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import VueScrollTo from "vue-scrollto";

Vue.use(VueRouter);
Vue.use(VueMeta);
Vue.use(VueSweetalert2);
Vue.use(VueScrollTo);

export default {
  name: "App",
  components: {
    TheMenu,
  },
  data() {
    return {
      routesNoMenu: [RouteNames.LOGIN_ROUTE],
    };
  },
  computed: {
    exibirMenu() {
      return !this.routesNoMenu.includes(this.$route.name);
    },
  },
};
</script>

<style lang="scss" scoped>
.app-conteudo {
  margin-top: 8rem;
}
</style>