import axios from "axios";
import axiosAuthenticated from "./AxiosAutenticated";

const queryString = require("query-string");

class VeiculosApi {
  cancelTokens = {
    getVeiculos: null,
    getVeiculo: null,
    getTiposCombustivel: null,
    salvarVeiculo: null,
    excluirVeiculo: null,
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async getTiposCombustivel() {
    try {
      const CancelTokens = axios.CancelToken;
      const source = CancelTokens.source();

      this.cancelTokens.getTiposCombustivel = source;

      const {
        data,
      } = await axiosAuthenticated.get(
        `${process.env.VUE_APP_BASE_API_URL}/api/v1/veiculos/tipos_combustivel`,
        { cancelToken: source.token }
      );

      this.cancelTokens.getTiposCombustivel = null;
      return data;
    } catch (error) {
      this.cancelTokens.getTiposCombustivel = null;
      throw error;
    }
  }

  async getVeiculos({ page, per_page, placa, modelo, veiculo_proprio }) {
    const CancelTokens = axios.CancelToken;
    const source = CancelTokens.source();

    this.cancelTokens.getVeiculos = source;

    const searchString = queryString.stringify({
      page,
      per_page,
      placa,
      modelo,
      veiculo_proprio,
    });

    const { data } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/veiculos?${searchString}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getVeiculos = null;

    return data;
  }

  async getVeiculo(id) {
    const CancelTokens = axios.CancelToken;
    const source = CancelTokens.source();

    this.cancelTokens.getVeiculo = source;

    const { data } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/veiculos/${id}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getVeiculo = null;
    return data;
  }

  async salvarVeiculo(veiculo) {
    try {
      const CancelTokens = axios.CancelToken;
      const source = CancelTokens.source();

      this.cancelTokens.salvarVeiculo = source;

      let method;
      let url;

      if (veiculo.id) {
        method = axiosAuthenticated.put;
        url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/veiculos/${veiculo.id}`;
      } else {
        method = axiosAuthenticated.post;
        url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/veiculos`;
      }
      const { data } = await method(url, veiculo, {
        cancelToken: source.token,
      });

      this.cancelTokens.salvarVeiculo = null;
      return data;
    } catch (err) {
      this.cancelTokens.salvarVeiculo = null;
      throw err;
    }
  }

  async excluirVeiculo(id) {
    const CancelTokens = axios.CancelToken;
    const source = CancelTokens.source();

    this.cancelTokens.excluirVeiculo = source;
    const { data } = await axiosAuthenticated.delete(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/veiculos/${id}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.excluirVeiculo = null;
    return data;
  }
}

export default VeiculosApi;
