<template>
  <div class="centro" v-if="motorista">
    <div class="header-show">
      <div class="esquerda">
        <router-link :to="{ name: MOTORISTAS, query: { ...$route.query } }"
          ><IconVoltar
        /></router-link>
        <h1>{{ motorista.nome }}</h1>
      </div>
      <BotaoEditarShow
        :to="{
          name: MOTORISTA_EDIT,
          params: motorista.id,
        }"
      />
    </div>
    <AppLoading v-if="carregando && !erroCarregando" />
    <AppErro v-if="!carregando && erroCarregando" />
    <template v-if="!carregando && !erroCarregando">
      <div class="caixa-show">
        <div class="row3">
          <p><b>Nome: </b> {{ motorista.nome }}</p>
          <p><b>CPF: </b> {{ motorista.cpf | cpf }}</p>
          <p><b>Tipo da CNH: </b> {{ motorista.label_tipo_cnh }}</p>
          <p>
            <b>Data de vencimento da CNH: </b>
            {{ motorista.data_vencimento_carteira_habilitacao | data }}
          </p>
          <p><b>CNH: </b> {{ motorista.cnh }}</p>
          <p><b>Ativo: </b> {{ motorista.ativo ? "Sim" : "Não" }}</p>
        </div>
      </div>
      <div class="historico">
        <p><b>Data de Criação: </b> {{ motorista.created_at | dataHora }}</p>
        <p>
          <b>Data de Atualização: </b> {{ motorista.updated_at | dataHora }}
        </p>
        <p><b>Criado Por: </b> {{ motorista.criado_por }}</p>
        <p><b>Atualizado Por: </b> {{ motorista.atualizado_por }}</p>
      </div>
    </template>
  </div>
</template>
<script>
import MotoristaApi from "../../services/api/MotoristaApi";
import BotaoEditarShow from "../../components/botoes/BotaoEditarShow.vue";
import IconVoltar from "../../assets/images/icon-voltar.svg";

import {
  MOTORISTAS,
  MOTORISTA_EDIT,
  MOTORISTA_SHOW,
} from "../../router/routeNames";

import AppLoading from "../../components/AppLoading";
import AppErro from "../../components/AppErro";

import filterCpf from "../../filters/cpf";
import { filterData, filterDataHora } from "../../filters/data";

export default {
  name: "MotoristaShow",
  metaInfo() {
    return {
      title: `Exibir Motorista`,
      titleTemplate: "%s - Gaia",
    };
  },
  components: {
    AppErro,
    AppLoading,
    BotaoEditarShow,
    IconVoltar,
  },
  data() {
    return {
      carregando: true,
      erroCarregando: false,
      motorista: null,
      MOTORISTAS,
      MOTORISTA_EDIT,
    };
  },
  created() {
    this.motoristaApi = new MotoristaApi();

    this.carregarDados();
  },
  watch: {
    $route: function (to, from) {
      if (to.params.id !== from.params.id && to.name === MOTORISTA_SHOW) {
        this.carregarDados();
      }
    },
  },
  filters: {
    cpf: filterCpf,
    dataHora: filterDataHora,
    data: filterData,
  },
  methods: {
    async carregarDados() {
      try {
        this.carregando = true;
        this.erroCarregando = false;

        const data = await this.motoristaApi.getMotorista(
          this.$route.params.id
        );
        this.motorista = data;
        this.carregando = false;
      } catch (err) {
        console.log(err);
        this.carregando = false;
        this.erroCarregando = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.header-show {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  .esquerda {
    display: flex;
    align-items: center;
    gap: 1rem;
  h1 {
    font-family: "Proxima-Bold";
    color: #014403;
    margin: 0;
  }
  }
}
.caixa-show {
  background-color: white;
  padding: 1rem;
  p {
    b {
      color: #014403;
    }
  }
}
.historico {
  margin-top: 1rem;
  p {
    margin: 0.3rem 0.5rem;
    font-size: 0.9rem;
  }
}
@media screen and (min-width: 50em) {
  .caixa-show {
    p {
      display: grid;
      gap: .5rem;
    }
  }
  .row3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  }
}
</style>