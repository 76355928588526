<template>
  <div class="centro">
    <div class="index-header">
      <h1>Motoristas</h1>
      <BotaoNovoIndex :to="{ name: MOTORISTA_NEW }" />
    </div>
    <AppLoading v-if="carregandoMotoristas" />
    <AppOverlayLoading v-if="excluindo">
      <span>Excluindo... Por favor, aguarde.</span>
    </AppOverlayLoading>
    <AppErro v-if="erroCarregarMotoristas && !carregandoMotoristas" />
    <MotoristasPesquisaForm
      v-model="filtro"
      @submit.prevent="buscar"
      v-if="!carregandoMotoristas && !erroCarregarMotoristas"
    />
    <div v-if="!carregandoMotoristas && !erroCarregarMotoristas">
      <div class="header-resultados">
        <h4>Resultado</h4>
        <AppResultadosPorPagina
          :per-page="perPage"
          @per-page-changed="perPageChanged"
        />
      </div>
      <AppTotalResultadosEncontrados :total-resultados="totalResultados" />
      <MotoristasResultados
        :motoristas="motoristas"
        @excluir-motorista="excluirMotorista"
      />
      <div class="paginacao">
        <Paginate
          v-model="page"
          :page-count="pageCount"
          :page-renge="5"
          prev-text="Anterior"
          next-text="Próxima"
          :clickHandler="mudarPagina"
        ></Paginate>
      </div>
    </div>
  </div>
</template>
<script>
import AppErro from "../../components/AppErro.vue";
import MotoristasPesquisaForm from "../../components/motoristas/MotoristasPesquisaForm";
import MotoristasResultados from "../../components/motoristas/MotoristasResultados";

import Paginate from "vuejs-paginate";
import BotaoNovoIndex from "../../components/botoes/BotaoNovoIndex.vue";
import AppLoading from "../../components/AppLoading.vue";
import AppOverlayLoading from "../../components/AppOverlayLoading.vue";
import AppResultadosPorPagina from "../../components/AppResultadosPorPagina.vue";
import AppTotalResultadosEncontrados from "../../components/AppTotalResultadosEncontrados.vue";
import filterNullsAndEmptyStrings from "../../helpers/filterNullsAndEmptyStrings";
import {
  MOTORISTA_NEW,
  MOTORISTAS,
  MOTORISTA_EDIT,
} from "../../router/routeNames";
import { DEFAULT_PER_PAGE } from "../../config";
import MotoristaApi from "../../services/api/MotoristaApi";

const isEqual = require("lodash.isequal");

export default {
  name: "MotoristaIndex",
  metaInfo: {
    title: "Motoristas",
    titleTemplate: "%s - Frotas",
  },
  components: {
    MotoristasResultados,
    MotoristasPesquisaForm,
    BotaoNovoIndex,
    AppLoading,
    AppOverlayLoading,
    AppResultadosPorPagina,
    AppTotalResultadosEncontrados,
    AppErro,
    Paginate,
  },
  data() {
    return {
      motoristas: [],
      totalResultados: 0,
      carregandoFiltro: true,
      carregandoMotoristas: true,
      erroCarregarMotoristas: false,
      excluindo: false,
      page: this.$route.query.page ? parseInt(this.$route.query.page) : 1,
      perPage: this.$route.query.per_page || DEFAULT_PER_PAGE,
      filtro: {
        nome: this.$route.query.nome,
        cpf: this.$route.query.cpf,
        cnh: this.$route.query.cnh,
        ativo: this.$route.query.ativo,
      },
      MOTORISTA_NEW,
      MOTORISTAS,
      MOTORISTA_EDIT,
    };
  },
  created() {
    this.motoristaApi = new MotoristaApi();
    this.carregarMotorista();
  },
  watch: {
    $route: function (to, from) {
      if (to.name == MOTORISTAS && !isEqual(to.query, from.query)) {
        this.carregarMotorista();
      }
    },
  },
  methods: {
    async carregarMotorista() {
      try {
        this.carregandoMotoristas = true;
        this.erroCarregarMotoristas = false;

        this.filtro = {
          nome: this.$route.query.nome || "",
          cpf: this.$route.query.cpf || "",
          cnh: this.$route.query.cnh || "",
          ativo: this.$route.query.ativo || null,
        };

        const data = await this.motoristaApi.getMotoristas({
          ...this.$route.query,
          page: this.$route.query.page || this.page,
          perPage: this.$route.query.perPage || this.perPage,
        });

        this.motoristas = data.resultados;
        this.totalResultados = data.total_resultados;

        this.carregandoMotoristas = false;
        this.carregandoFiltro = false;
      } catch (err) {
        console.log(err);
        this.erroCarregarMotoristas = true;
        this.carregandoMotoristas = false;
        this.carregandoFiltro = false;
      }
    },
    async excluirMotorista(id) {
      try {
        const result = await this.$swal.fire({
          title: "Você tem certeza?",
          text: "Você não poderá desfazer essa ação",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        });

        if (!result.isConfirmed) {
          return;
        }

        this.excluindo = true;

        await this.motoristaApi.excluirMotorista(id);
        this.excluindo = false;

        this.$swal({
          icon: "sucess",
          position: "top-end",
          title: "Motorista excluído com sucesso!",
          timer: 3000,
          toast: true,
          showConfirmButton: false,
        });

        if (this.motoristas.length == 1 && this.page > 1) {
          this.page--;
          this.$router.push({
            ...this.$route.query,
            perPage: this.$route.query.perPage || this.perPage,
            page: this.page,
          });
        } else {
          this.carregarMotorista();
        }
      } catch (err) {
        console.log(err);
        this.excluindo = false;
        this.$swal({
          icon: "error",
          title:
            "Não foi possível excluir o registro. Por favor tente novamente.",
          showConfirmButton: true,
        });
      }
    },
    buscar() {
      this.page = 1;
      this.$router.push({
        query: filterNullsAndEmptyStrings({
          ...this.filtro,
          page: this.page,
          per_page: this.perPage,
        }),
      });
    },
    mudarPagina(pageNum) {
      this.page = pageNum;
      this.$router.push({
        name: MOTORISTAS,
        query: {
          ...this.$route.query,
          page: this.page,
          per_page: this.perPage,
        },
      });
    },
    perPageChanged(newValuePerPage) {
      if (newValuePerPage < this.perPage) {
        this.perPage = newValuePerPage;
        this.mudarPagina(this.page);
      } else {
        this.perPage = newValuePerPage;
        this.mudarPagina(1);
      }
    },
  },
  computed: {
    pageCount() {
      if (
        this.totalResultados != undefined &&
        this.totalResultados != null &&
        this.totalResultados > 0
      ) {
        return Math.ceil(this.totalResultados / this.perPage);
      }
      return 0;
    },
  },
};
</script>
<style lang="scss" scoped>
.index-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  gap: 1rem;
  h1 {
    font-family: "Proxima-Bold";
    color: #014403;
    margin: 0;
  }
}
.header-resultados {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  h4 {
    font-family: "Proxima-Bold";
    margin-right: 4rem;
    color: #014403;
    font-size: 1.4rem;
    margin: 1rem 1rem 1rem 0rem;
  }
}
</style>
