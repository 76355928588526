<template>
  <div>
    <label for="resultadosPorPagina">Resultados por página: </label>
    <select @change="valueSelected" id="resultadosPorPagina">
      <option
        v-for="option in options"
        :key="option"
        :value="option"
        :selected="option === valuePerPage"
      >
        {{ option }}
      </option>
    </select>
  </div>
</template>

<script>
import { DEFAULT_PER_PAGE } from "../config";

// eventos:
// @per-page-changed com o novo valor per-page selecionado

export default {
  name: "AppResultadosPorPagina",
  props: {
    perPage: {
      type: [String, Number],
      default: DEFAULT_PER_PAGE,
    },
  },
  data() {
    return {
      options: [10, 25, 50, 100],
    };
  },
  methods: {
    valueSelected(event) {
      const newValue = event.target.value;
      this.$emit("per-page-changed", newValue);
    },
  },
  computed: {
    valuePerPage() {
      if (typeof this.perPage === "string") {
        return parseInt(this.perPage);
      }
      return this.perPage;
    },
  },
};
</script>

<style lang="scss" scoped>

  select{
    height: 1.5rem;
    font-size: 1rem;
  }
</style>
