<template>
  <div class="resultados">
    <table>
      <thead>
        <tr>
          <th>CPF</th>
          <th>Nome</th>
          <th class="text-center">CNH</th>
          <th class="text-center">Ativo</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="motorista in motoristas" :key="motorista.id">
          <td class="topo-card">{{ motorista.cpf | cpf }}</td>
          <td data-label="Nome: ">{{ motorista.nome }}</td>
          <td class="text-center" data-label="CNH: ">{{ motorista.cnh }}</td>
          <td class="text-center" data-label="Ativo? ">
            {{ motorista.ativo ? "Sim" : "Não" }}
          </td>
          <td class="acoes">
            <BotaoEditarTabela
              :to="{
                name: MOTORISTA_EDIT,
                params: { id: motorista.id },
              }"
            />
            <BotaoExibirTabela
              :to="{
                name: MOTORISTA_SHOW,
                params: { id: motorista.id },
              }"
            />

            <BotaoDeletarTabela @click="excluir(motorista.id)" />
          </td>
        </tr>
        <tr v-if="motoristas.length == 0">
          <td class="text-center" colspan="5">Nenhum registro encontrado</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import BotaoEditarTabela from "../botoes/botoes_tabelas_resultados/BotaoEditarTabela";
import BotaoDeletarTabela from "../botoes/botoes_tabelas_resultados/BotaoDeletarTabela";
import { MOTORISTA_SHOW, MOTORISTA_EDIT } from "../../router/routeNames";
import BotaoExibirTabela from "../botoes/botoes_tabelas_resultados/BotaoExibirTabela";

import filterCpf from "../../filters/cpf";

export default {
  name: "MotoristasResultados",
  components: {
    BotaoEditarTabela,
    BotaoDeletarTabela,
    BotaoExibirTabela,
  },
  props: {
    motoristas: {
      type: Array,
      default: () => [],
    },
  },
  filters: {
    cpf: filterCpf,
  },
  data() {
    return {
      MOTORISTA_SHOW,
      MOTORISTA_EDIT,
    };
  },
  methods: {
    excluir(id) {
      this.$emit("excluir-motorista", id);
    },
  },
};
</script>
<style lang="scss" scoped>
.resultados {
  table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 0.2rem;
    overflow: hidden;
    thead {
      background-color: #014403;
      color: white;

      th {
        text-align: left;
        padding: 1rem 0.5rem;
      }
      .text-center {
        text-align: center;
      }
    }
    tbody {
      td {
        padding: 0.5rem;
      }
      tr:nth-child(even) {
        background-color: white;
      }
      .text-center {
        text-align: center;
      }
      .acoes {
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
      }
    }
  }
}
@media screen and (max-width: 64rem) {
  .resultados {
    table {
      width: 100%;
      tbody {
        background-color: transparent;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0.5rem;
      }
      td.text-center {
        text-align: left;
      }
      thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }

      tr {
        // margin: .5rem;
        display: block;
        background-color: white;
        width: 100% !important;
        border-radius: 0.2rem;
        overflow: hidden;
        td {
          padding: 0rem !important;
          min-height: 1.94rem;
        }
        .acoes {
          display: flex;
          justify-content: center;
        }

        .acoes::before {
          display: none;
        }
      }
      td {
        display: block;
        min-height: 1.3rem;
        margin: 0.3rem 0.5rem;
        img {
          height: 1.5rem;
          margin: 0rem 1rem;
        }
      }

      td::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        color: #054700;
        font-family: "Proxima-SemiBold";
        margin-right: 0.5rem;
      }
      .topo-card {
        background-color: #014403;
        font-family: "Proxima-Bold";
        text-align: center;
        margin: 0rem 0rem 1rem 0rem;
        font-size: 0.9rem;
        color: white;
        display: flex !important;
        justify-content: center;
        align-items: center;
        a {
          color: white;
          text-decoration: none;
        }
      }
    }
  }
}

@media screen and (max-width: 50rem) {
  tbody {
    grid-template-columns: 1fr !important;
  }
}
</style>