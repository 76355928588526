<template>
  <div class="centro" v-if="veiculo">
    <div class="header-show">
      <div class="esquerda">
        <router-link :to="{ name: VEICULOS, query: { ...$route.query } }">
          <IconVoltar />
        </router-link>
        <h1>Veículo - {{ veiculo.placa }}</h1>
      </div>
      <BotaoEditarShow :to="{ name: VEICULO_EDIT, params: veiculo.id }" />
    </div>
    <AppLoading v-if="carregando && !erroCarregando" />
    <AppErro v-if="!carregando && erroCarregando" />
    <template v-if="!carregando && !erroCarregando">
      <div class="caixa-show">
        <div class="row3">
          <p><b>Placa:</b> {{ veiculo.placa }}</p>
          <p><b>Modelo:</b> {{ veiculo.modelo }}</p>
          <p><b>Ano do Veículo:</b> {{ veiculo.ano_veiculo }}</p>
        </div>
        <div class="row3">
          <p><b>Marca:</b> {{ veiculo.marca.nome }}</p>
          <p><b>Tipo do Veículo:</b> {{ veiculo.tipo_veiculo.nome }}</p>
          <p>
            <b>Data de vencimento do IPVA:</b>
            {{ veiculo.data_vencimento_ipva | data }}
          </p>
        </div>
        <div class="row3">
          <p>
            <b>Tipo de Combustível:</b> {{ veiculo.label_tipo_combustivel }}
          </p>
          <p>
            <b>Veículo Próprio?:</b>
            {{ veiculo.veiculo_proprio ? "Sim" : "Não" }}
          </p>
        </div>
      </div>

      <div class="historico">
        <p><b>Criado Por:</b> {{ veiculo.criado_por }}</p>
        <p><b>Atualizado Por:</b> {{ veiculo.atualizado_por }}</p>
        <p><b>Data de Criação:</b> {{ veiculo.created_at | dataHora }}</p>
        <p><b>Data de Atualização:</b> {{ veiculo.updated_at | dataHora }}</p>
      </div>
    </template>
  </div>
</template>
<script>
import AppErro from "../../components/AppErro";
import AppLoading from "../../components/AppLoading";
import BotaoEditarShow from "../../components/botoes/BotaoEditarShow";
import { VEICULOS, VEICULO_EDIT, VEICULO_SHOW } from "../../router/routeNames";
import VeiculoApi from "../../services/api/VeiculosApi";
import IconVoltar from "../../assets/images/icon-voltar.svg";
import { filterData, filterDataHora } from "../../filters/data";

export default {
  name: "VeiculoShow",
  metaInfo() {
    return {
      title: `Exibir Veículo`,
      titleTemplate: "%s - Frotas",
    };
  },
  components: {
    BotaoEditarShow,
    AppLoading,
    AppErro,
    IconVoltar,
  },
  data() {
    return {
      VEICULOS,
      VEICULO_EDIT,
      VEICULO_SHOW,
      carregando: true,
      erroCarregando: false,
      veiculo: null,
    };
  },
  created() {
    this.veiculoApi = new VeiculoApi();
    this.carregarDados();
  },
  watch: {
    $route: function (to, from) {
      if (to.params.id !== from.params.id && to.name === VEICULO_SHOW) {
        this.carregarDados();
      }
    },
  },
  filters: {
    dataHora: filterDataHora,
    data: filterData,
  },
  methods: {
    async carregarDados() {
      try {
        this.carregando = true;
        this.erroCarregando = false;

        const data = await this.veiculoApi.getVeiculo(this.$route.params.id);

        this.veiculo = data;

        this.carregando = false;
      } catch (err) {
        console.log(err);
        this.carregando = false;
        this.erroCarregando = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.header-show {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  .esquerda {
    display: flex;
    align-items: center;
    gap: 1rem;
  h1 {
    font-family: "Proxima-Bold";
    color: #014403;
    margin: 0;
  }
  }
}
.caixa-show {
  background-color: white;
  padding: 1rem;
  p {
    b {
      color: #014403;
    }
  }
}
.historico {
  margin-top: 1rem;
  p {
    margin: 0.3rem 0.5rem;
    font-size: 0.9rem;
  }
}
@media screen and (min-width: 50em) {
  .caixa-show {
    p {
      display: grid;
      gap: .5rem;
    }
  }
  .row3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  }
}
</style>