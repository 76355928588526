import * as RouteNames from "../routeNames";
import MotoristaIndex from "../../views/motorista/MotoristaIndex"
import MotoristaForm from "../../views/motorista/MotoristaForm"
import MotoristaShow from "../../views/motorista/MotoristaShow"

//raiz indica se é uma rota que pode ser a inicial para o usuario que logar (normalmente vao ser so os indexes de cada crud)
//colocar as rotas em ordem de prioridade para login (as mais no topo vao ser a tela inicial do usuario se ele tiver permissao)

const motoristas = [
  {
    name: RouteNames.MOTORISTAS,
    component: MotoristaIndex,
    path: "/motoristas",
    meta:{
      requerPermissao: true,
      raiz: true,
      permissoes: ["motoristas"],
    },
  },
  {
    name: RouteNames.MOTORISTA_EDIT,
    component: MotoristaForm,
    path: "/motoristas/:id/edit",
    meta:{
      requerPermissao: true,
      permissoes: ["motoristas"],
    },
  },
  {
    name: RouteNames.MOTORISTA_NEW,
    component: MotoristaForm,
    path: "/motoristas/new",
    meta:{
      requerPermissao: true,
      permissoes: ["motoristas"],
    },
  },
  {
    name: RouteNames.MOTORISTA_SHOW,
    component: MotoristaShow,
    path: "/motoristas/:id/show",
    meta:{
      requerPermissao: true,
      permissoes: ["motoristas"],
    },
  },
];

export default motoristas;