<template>
  <div class="the-menu">
    <nav>
      <div class="centro">
        <span>{{version}}</span>
        <div class="direita">
          <span>{{ nome }}</span>
          <a @click="logout"><IconSair /></a>
        </div>
      </div>
    </nav>
    <div class="barra-menu centro">
      <div class="logo-menu">
        <router-link :to="{ name: VIAGENS }">
          <img src="../../assets/images/logo-frotas2.png" alt="" />
        </router-link>
      </div>
      <input class="checkmenu" id="atv-menu" type="checkbox" />
      <label for="atv-menu">
        <div class="menu">
          <span class="hamburger"></span>
        </div>
      </label>
      <div class="lista-menu">
        <div class="centro">
          <ul>
            <li v-for="menuItem in menu" :key="menuItem.label">
              <template v-if="menuItem.routeName">
                <router-link :to="{ name: menuItem.routeName }">
                  <component :is="menuItem.icon" v-if="menuItem.icon" />
                  {{ menuItem.label }}
                </router-link>
              </template>
              <template v-else>
                <a class="a-menu" @click="toggleClass">
                  <component :is="menuItem.icon" v-if="menuItem.icon" />
                  {{ menuItem.label }}
                </a>
                <ol>
                  <li
                    v-for="subItem in menuItem.routeList"
                    :key="subItem.label"
                  >
                    <router-link :to="{ name: subItem.routeName }">
                      {{ subItem.label }}
                    </router-link>
                  </li>
                </ol>
              </template>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { LOGIN_ROUTE, VIAGENS } from "../../router/routeNames";
import * as Auth from "../../services/Auth";

import IconSair from "../../assets/images/icon-sair.svg";

import menu from "../../router/menu";

const get = require("lodash.get");

export default {
  name: "TheMenu",
  components: {
    IconSair,
  },
  data() {
    const usuario = Auth.getUsuario();

    const filtrarRotas = (item) => {
      if (item.routeName) {
        const route = this.$router.options.routes.find(
          (route) => route.name == item.routeName
        );
        const requerPermissao = get(route, "meta.requerPermissao", false);
        const permissoes = get(route, "meta.permissoes", []);

        if (
          route &&
          (!requerPermissao ||
            permissoes.every((permissao) =>
              usuario.funcionalidades.includes(permissao)
            ))
        ) {
          return item;
        }
        return null;
      } else if (item.routeList) {
        const itemFiltrado = {
          ...item,
          routeList: item.routeList.reduce((acc, route) => {
            const rotaFiltrada = filtrarRotas(route);

            if (rotaFiltrada) return acc.concat(rotaFiltrada);

            return acc;
          }, []),
        };

        if (
          itemFiltrado &&
          itemFiltrado.routeList &&
          itemFiltrado.routeList.length > 0
        )
          return itemFiltrado;

        return null;
      }
      return null;
    };

    let menuPermitido = menu.reduce((accumulator, element) => {
      const rotaFiltrada = filtrarRotas(element);

      if (rotaFiltrada && (rotaFiltrada.routeName || rotaFiltrada.routeList))
        return accumulator.concat(rotaFiltrada);

      return accumulator;
    }, []);

    return {
      VIAGENS,
      menu: menuPermitido,
      version: process.env.VUE_APP_VERSION
    };
  },
  watch: {
    "$route.name": function () {
      //se rota muda, tenho que remover a classe menu-aberto dos links e fechar o menu
      this.fecharMenu();
    },
  },
  computed: {
    nome() {
      if (Auth.getUsuario()) {
        return Auth.getUsuario().nome;
      }
      return null;
    },
  },
  methods: {
    logout() {
      Auth.logout();
      this.$router.replace({ name: LOGIN_ROUTE });
    },
    toggleClass(event) {
      if (event.target.nodeName.toUpperCase() === "A") {
        event.srcElement.classList.toggle("menu-aberto");
        if (event.target.href && event.target.href != "") {
          this.fecharMenu();
        }
      } else if (
        event.target.parentNode &&
        event.target.parentNode.nodeName.toUpperCase() === "A"
      ) {
        event.target.parentElement.classList.toggle("menu-aberto");
      }
    },
    removeClassMenuAberto() {
      const linksAbertos = document.getElementsByClassName("menu-aberto");
      while (linksAbertos && linksAbertos.length > 0) {
        linksAbertos[0].classList.remove("menu-aberto");
      }
    },
    fecharMenu() {
      const menu = document.getElementById("atv-menu");
      if (menu) {
        menu.checked = false;
        this.removeClassMenuAberto();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.the-menu {
  position: fixed;
  width: 100%;
  top: 0;
  background-color: white;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.521);
  z-index: 2000;
}
nav {
  background: #014403;
  padding: 0.3rem 0.5rem 0.3rem;
  .centro {
    color: white;
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
  }
  .direita {
    display: flex;
    align-items: center;
  }
  svg {
    height: 0.8rem;
    width: 0.8rem;
    margin-left: 0.5rem;
    cursor: pointer;
  }
}
.barra-menu {
  padding: 1rem 0.5rem;
  background-color: white;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  .logo-menu {
    text-align: left;
    img {
      height: 3rem;
    }
  }
  .menu {
    display: none;
  }

  .checkmenu {
    display: none;
  }
  .lista-menu {
    ul {
      height: auto;
      display: grid;
      grid-template-columns: auto auto auto auto;
      gap: 2rem;
      min-height: auto;
      margin: 0 0 -1rem;
      background-color: transparent;
      width: auto;
      list-style: none;
      li {
        position: relative;
        display: grid;
        justify-items: center;
      }
      a {
        display: grid;
        justify-items: center;
        text-decoration: none;
        color: black;
        padding-bottom: 1rem;
        position: relative;
        cursor: pointer;
        svg {
          margin: 0 0 0.5rem;
          fill: #8a8a8a;
          transition: 0.2s;
          height: 1.8rem;
          width: 1.8rem;
        }
      }
      a:hover:after,
      .router-link-active:after {
        content: "";
        display: block;
        height: 3px;
        width: 100%;
        border-radius: 0.1rem;
        background-color: #ffaa0f;
        font-family: "Proxima-Regular";
        position: absolute;
        bottom: 0;
        left: 0;
        transition: 0.2s;
        svg {
          fill: #014403;
        }
      }
      a:hover,
      .router-link-active {
        font-family: "Proxima-semibold";
        color: #014403;
        svg {
          fill: #014403;
        }
      }
      li:hover {
        ol {
          height: 5rem;
        }
      }
      ol {
        position: absolute;
        list-style: none;
        margin: 4.4rem 0 0 0;
        padding: 0;
        height: 0;
        overflow: hidden;
        background-color: white;
        transition: 0.3s;
        box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.521);
        li {
          a {
            display: block;
            padding: 0.5rem 2rem 1rem;
            width: 8rem;
          }
          a:hover {
            background-color: #08730c27;
          }
          a:after {
            display: none !important;
          }
        }
      }
    }
  }
}
</style>