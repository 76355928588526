<template>
  <div class="nao-encontrado">
    <img src="../assets/images/nao-encontrado.png" alt="">
    <h1>Página não encontrada</h1>
  </div>
</template>

<script>
export default {
  name: "NaoEncontrado",
  metaInfo: {
    title: "Não encontrado",
    titleTemplate: "%s - Frotas",
  },
};
</script>

<style lang="scss" scoped>
.nao-encontrado{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 13rem;
  img{
    max-width: 25rem;
    width: 90%;
  }
  h1{
    color: red;
    font-family: 'Proxima-Bold';
    text-align: center;
  }
}
</style>
