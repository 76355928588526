var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grupo-calendar"},[_c('ViagensResultadosCalendarioHeader',_vm._g({attrs:{"ano-referente":_vm.anoReferente,"scroll-y":_vm.scrollY,"top":375,"mes-referente":_vm.mesReferente}},_vm.$listeners)),_c('div',{staticClass:"mes"},_vm._l((_vm.semanasMes),function(nSemana){return _c('div',{key:nSemana,staticClass:"semana"},_vm._l((7),function(nDia){return _c('div',{key:nDia,staticClass:"dia",class:{
          'fim-de-semana': _vm.isFimDeSemana(_vm.diaDoMes(nSemana, nDia)),
          'dia-atual': _vm.isDiaAtual(_vm.diaDoMes(nSemana, nDia)),
        }},[(
            // verificando os períodos fora do mês atual do calendário
            !(
              (nSemana == 1 && nDia < _vm.semanaInicio) ||
              (nSemana == _vm.semanasMes && nDia > _vm.semanaFim)
            )
          )?[_c('div',{staticClass:"header-dia",attrs:{"title":"Criar uma nova Viagem"},on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.chamarNew.apply(null, arguments)}}},[_c('h2',[_vm._v(_vm._s(_vm.diaDoMes(nSemana, nDia)))]),(_vm.viagensDoDia(_vm.diaDoMes(nSemana, nDia)))?_c('span',{staticClass:"numero-viagens-dia"},[_vm._v(_vm._s(_vm.viagensDoDia(_vm.diaDoMes(nSemana, nDia)).viagens_do_dia.length)+" "+_vm._s(_vm.viagensDoDia(_vm.diaDoMes(nSemana, nDia)).viagens_do_dia.length == 1 ? "Viagem" : "Viagens"))]):_vm._e()]),(_vm.viagensDoDia(_vm.diaDoMes(nSemana, nDia)))?_c('ViagensResultadosCalendarioViagensDia',{attrs:{"viagem":_vm.viagensDoDia(_vm.diaDoMes(nSemana, nDia)),"mesReferente":_vm.mesReferente,"anoReferente":_vm.anoReferente,"isDiaAtual":_vm.isDiaAtual(_vm.diaDoMes(nSemana, nDia)),"valorDeCorte":3},on:{"chamar-show":_vm.chamarShow}}):_vm._e()]:_vm._e()],2)}),0)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }