<template>
  <VueSimpleAutocomplete
    :items="marcas"
    :selected-item="marca"
    @change="searchMarca"
    @value-selected="onMarcaSelected"
    :is-loading="loadingMarca"
    :get-display-value="(marca) => marca.nome"
    :min-length="2"
    placeholder="Digite para pesquisar"
  ></VueSimpleAutocomplete>
</template>

<script>
import VueSimpleAutocomplete from "vue-simple-autocomplete";
import MarcasApi from "../../services/api/MarcasApi";

export default {
  name: "MarcaAutoComplete",
  components: {
    VueSimpleAutocomplete,
  },
  model: {
    prop: "marca",
    event: "change",
  },
  props: {
    marca: Object,
  },
  data() {
    return {
      marcas: [],
      loadingMarca: false,
    };
  },
  created() {
    this.marcasApi = new MarcasApi();
  },
  methods: {
    async searchMarca(term) {
      try {
        if (this.marcasApi.cancelTokens.getMarcas) {
          this.marcasApi.cancelTokens.getMarcas.cancel(
            "Usuário fez outra busca"
          );
        }

        this.marcas = [];
        this.$emit("change", {});

        if (!term || term.length === 0) {
          return;
        }
        this.loadingMarca = true;
        const data = await this.marcasApi.getMarcas({ nome: term });

        this.marcas = data.resultados;

        this.loadingMarca = false;
      } catch (err) {
        if (!this.marcasApi.isCancel(err)) {
          this.loadingMarca = false;
        }
      }
    },
    onMarcaSelected(marca) {
      this.$emit("change", marca);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
