<template>
  <div v-if="!erroCarregando && !carregando" class="caixa-passageiros">
    <div class="title">
      <h4>Passageiros</h4>
      <button
        v-if="passageiros.length < limiteMaximoPassageiroViagem"
        title="Adicionar Benefício"
        @click="adicionar"
        type="button"
      >
        <span>+</span>Adicionar
      </button>
    </div>
    <div v-if="passageiros.length === 0">
      <p class="text-center">Nenhum passageiro cadastrado para esta viagem</p>
    </div>
    <div class="row4" v-if="passageiros.length > 0">
      <div v-for="(passageiro, index) in passageiros" :key="index">
        <template v-if="!passageiro._destroy">
          <div class="item">
            <label>Passageiro {{ index + 1 }}:</label>
            <div class="disp-flex">
              <input
                type="text"
                :value="passageiro.nome"
                @input="(event) => updateValue(index, event.target.value)"
              />
              <button @click="remover(index)" type="button">
                <IconDelete />
              </button>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="item">
            <div class="disp-flex">
              <p>Registro será removido</p>
              <button type="button" @click="desfazerRemocao(index)">
                <IconUndo />
              </button>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import IconDelete from "../../assets/images/icon-delete.svg";
import IconUndo from "../../assets/images/icon-undo.svg";
import ConfiguracaoApi from "../../services/api/ConfiguracaoApi";

export default {
  name: "ViagemPassageirosFormulario",
  components: {
    IconDelete,
    IconUndo,
  },
  model: {
    prop: "passageiros",
    event: "update",
  },
  props: {
    passageiros: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      limiteMaximoPassageiroViagem: null,
      carregando: false,
      erroCarregando: false,
    };
  },
  created() {
    this.configuracaoApi = new ConfiguracaoApi();
    this.carregarConfiguracao();
  },
  methods: {
    async carregarConfiguracao() {
      try {
        this.erroCarregando = false;
        this.carregando = true;

        const data = await this.configuracaoApi.getConfiguracao();

        this.limiteMaximoPassageiroViagem =
          data.limite_maximo_passageiro_viagem;

        this.carregando = false;
      } catch (e) {
        console.log(e);
        this.carregando = false;
        this.erroCarregando = true;
      }
    },
    adicionar() {
      this.$emit(
        "update",
        this.passageiros.concat({
          id: null,
          nome: null,
        })
      );
    },
    remover(index) {
      if (this.passageiros[index].id) {
        this.passageiros[index]._destroy = true;
        this.$emit("update", [...this.passageiros]);
      } else {
        this.$emit(
          "update",
          this.passageiros.filter((ele, idx) => idx !== index)
        );
      }
    },
    updateValue(index, value) {
      this.passageiros[index].nome = value;
      this.$emit("update", this.passageiros);
    },
    desfazerRemocao(index) {
      this.passageiros[index]._destroy = false;
      this.$emit("update", [...this.passageiros]);
    },
  },
};
</script>
<style lang="scss" scoped>
.caixa-passageiros {
  border-top: #6e6e6e solid 0.1rem;
  margin-top: 1.5rem;
  .text-center {
    text-align: center;
    font-style: italic;
  }
  .title {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    h4 {
      font-family: "Proxima-Semibold";
      font-size: 1.3rem;
      margin: 1rem 0rem;
      color: #014403;
    }
    button {
      display: flex;
      align-items: center;
      font-size: 1rem;
      height: 2rem;
      color: #014403;
      border: #014403 solid 0.15rem;
      background-color: transparent;
      border-radius: 0.2rem;
      font-family: "Proxima-SemiBold";
      padding: 0.5rem 1rem;
      margin-left: 1rem;
      cursor: pointer;
      transition: 0.2s;
      span {
        color: #ffaa0f;
        font-family: "Proxima-Bold";
        font-size: 1.5rem;
        margin-right: 0.5rem;
      }
    }
    button:hover {
      background-color: #014403;
      color: white;
    }
  }
  .item {
    display: grid;
    margin: 1rem 0;
    gap: 0.5rem;
    label {
      font-family: "Proxima-Regular";
      margin-bottom: 0.2rem;
    }
    span {
      color: red;
    }
    input {
      height: 1.5rem;
      font-size: 1rem;
    }
    button {
      border: none;
      background-color: transparent;
      cursor: pointer;
      svg {
        width: 1.5rem;
        height: 1.5rem;
        fill: red;
      }
    }
    .disp-flex {
      display: flex;
    }
  }
}
@media screen and(min-width: '30em') {
  .row4 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 1rem;
  }
}
@media screen and(min-width: '51em') {
  .row4 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 0 1rem;
  }
}
</style>