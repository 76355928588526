<template>
  <div class="centro">
    <AppLoading v-if="carregando && !erroCarregar" />
    <div id="erro-container">
      <AppErrosFormularios
        v-if="erroSalvar && erroSalvar.length > 0"
        :erros="erroSalvar"
      />
      <AppErro v-if="erroCarregar && !carregando"></AppErro>
    </div>
    <template v-if="!carregando && !erroCarregar">
      <div class="parte1">
        <div class="esquerda">
          <router-link :to="{ name: VIAGENS, query: { ...$route.query } }">
            <IconVoltar />
          </router-link>
          <h1>
            {{ $route.name == VIAGEM_NEW ? "Nova Viagem" : "Editar Viagem" }}
          </h1>
        </div>
        <BotaoVisualizarForm
          v-if="$route.params.id"
          :to="{ name: VIAGEM_SHOW, params: { id: $route.params.id } }"
        />
      </div>
      <ViagemFormulario
        @submit.prevent="salvar"
        v-model="viagem"
        :veiculos="veiculos"
        :motoristas="motoristas"
        :statusViagem="statusViagem"
        :setoresSolicitante="setoresSolicitante"
      ></ViagemFormulario>
    </template>
  </div>
</template>
<script>
import {
  VIAGEM_SHOW,
  VIAGEM_NEW,
  VIAGENS,
  VIAGEM_EDIT,
} from "../../router/routeNames";
import AppLoading from "../../components/AppLoading";
import AppErro from "../../components/AppErro";
import ViagemApi from "../../services/api/ViagemApi";
import IconVoltar from "../../assets/images/icon-voltar.svg";
import BotaoVisualizarForm from "../../components/botoes/BotaoVisualizarForm";
import AppErrosFormularios from "../../components/AppErrosFormularios";
import ViagemFormulario from "../../components/viagens/ViagemFormulario";

export default {
  name: "ViagemForm",
  metaInfo() {
    return {
      title: this.$route.name == VIAGEM_EDIT ? "Editar Viagem" : "Nova Viagem",
      titleTemplate: "%s - Frotas",
    };
  },
  components: {
    AppErro,
    AppLoading,
    BotaoVisualizarForm,
    IconVoltar,
    AppErrosFormularios,
    ViagemFormulario,
  },
  data() {
    return {
      carregando: false,
      erroCarregar: false,
      viagem: {
        id: null,
        destino: "",
        status_viagem: "aprovada",
        data_agendamento_previsto: null,
        motivo_viagem: "",
        local_saida: "",
        veiculo_id: null,
        motorista_id: null,
        data_inicio_viagem: null,
        km_inicial: null,
        data_retorno_viagem: null,
        km_final: null,
        teve_multa: false,
        valor_multa: 0,
        passageiros_attributes: [],
      },
      statusViagem: [],
      motoristas: [],
      setoresSolicitante: [],
      veiculos: [],
      erroSalvar: null,
      salvando: false,
      VIAGEM_SHOW,
      VIAGEM_NEW,
      VIAGEM_EDIT,
      VIAGENS,
    };
  },
  created() {
    this.viagemApi = new ViagemApi();
    this.carregarSelects();
    if (this.$route.params.id) {
      this.carregarViagem();
    }
  },
  methods: {
    async carregarSelects() {
      try {
        this.carregando = true;
        this.erroCarregar = false;

        const dataStatusViagem = await this.viagemApi.getStatusViagem();
        const dataSetoresSolicitante = await this.viagemApi.getSetoresSic();
        const dataMotorista = await this.viagemApi.getMotoristas();
        const dataVeiculo = await this.viagemApi.getVeiculos();

        this.statusViagem = dataStatusViagem;
        this.motoristas = dataMotorista;
        this.veiculos = dataVeiculo;
        this.setoresSolicitante = dataSetoresSolicitante;

        this.carregando = false;
      } catch (e) {
        console.log(e);
        this.carregando = false;
        this.erroCarregar = true;
      }
    },
    async carregarViagem() {
      try {
        this.carregando = true;
        this.erroCarregar = false;

        const data = await this.viagemApi.getViagem(this.$route.params.id);
        this.viagem = data;

        this.carregando = false;
      } catch (e) {
        console.log(e);
        this.carregando = false;
        this.erroCarregar = true;
      }
    },
    async salvar() {
      try {
        this.salvando = true;
        this.erroSalvar = null;
        const data = await this.viagemApi.salvarViagem({
          ...this.viagem,
          setor_solicitante_id: this.viagem.setor_solicitante
            ? this.viagem.setor_solicitante.id
            : null,
        });

        this.$emit("on-reload-data");
        this.salvando = false;

        this.$swal({
          toast: true,
          icon: "success",
          position: "top-end",
          title: "Viagem salvo com sucesso!",
          timer: 3000,
          showConfirmButton: false,
        });

        this.$router.push({
          name: VIAGEM_SHOW,
          params: { id: data.id },
        });
      } catch (err) {
        console.log(err);
        this.salvando = false;

        let data;
        if (err.response) {
          data = err.response.data;
        }

        if (data) {
          if (Array.isArray(data)) {
            this.erroSalvar = data;
          } else if (data.mensagem) {
            this.erroSalvar = [data.mensagem];
          } else {
            this.erroSalvar = [
              "Houve um erro ao salvar os dados. Por favor, tente novamente",
            ];
          }
        } else {
          this.erroSalvar = [
            "Houve um erro ao salvar os dados. Por favor, tente novamente",
          ];
        }
        this.$scrollTo("#erro-container", {
          offset: -200,
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.parte1 {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  .esquerda {
    display: flex;
    align-items: center;
    gap: 1rem;
    h1 {
      font-family: "Proxima-Bold";
      color: #014403;
      margin: 0;
    }
  }
}
.centro {
  padding-bottom: 5rem;
}
</style>