import * as RouteNames from '../routeNames';
import TipoVeiculoIndex from '../../views/tipo_veiculo/TipoVeiculoIndex'
import TipoVeiculoForm from '../../views/tipo_veiculo/TipoVeiculoForm'

const tiposVeiculo = [
    {
        name: RouteNames.TIPOS_VEICULO,
        component: TipoVeiculoIndex,
        path: "/tipos_veiculo",
        meta: {
            requerPermissao: true,
            raiz: true,
            permissoes: ["tipos_veiculo"]
        },
        children: [
            {
                name: RouteNames.TIPO_VEICULO_NEW,
                component: TipoVeiculoForm,
                path: "/tipos_veiculo/new",
                meta:{
                    requerPermissao: true,
                    permissoes: ["tipos_veiculo"]
                }
            },
            {
                name: RouteNames.TIPO_VEICULO_EDIT,
                component: TipoVeiculoForm,
                path: "/tipos_veiculos/:id/edit",
                meta:{
                    requerPermissao: true,
                    permissoes: ["tipos_veiculo"]
                }
            }
        ]
    },
]

export default tiposVeiculo;