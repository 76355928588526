<template>
  <div>
    <div class="caixa-show">
      <div class="row3">
        <p><b>Status do Agendamento:</b> {{ viagem.status_viagem_label }}</p>
        <p>
          <b>Data e Hora Prevista da Viagem:</b>
          {{ viagem.data_agendamento_previsto | dataHora }}
        </p>
        <p><b>Setor Solicitante:</b> {{ viagem.setor_solicitante.nome }}</p>
      </div>
      <p><b>Motivo da Viagem:</b> {{ viagem.motivo_viagem }}</p>
      <div class="row2">
        <p><b>Local de Destino:</b> {{ viagem.destino }}</p>
        <p><b>Local de Embarque:</b> {{ viagem.local_saida }}</p>
      </div>
      <div class="row2">
        <p>
          <b>Motorista:</b>
          <template v-if="viagem.motorista">
            {{ viagem.motorista.nome }}
          </template>
        </p>
        <p>
          <b>Veículo:</b>
          <template v-if="viagem.veiculo">
            {{
              viagem.veiculo.placa +
              " - " +
              viagem.veiculo.modelo +
              " - " +
              viagem.veiculo.marca.nome
            }}
          </template>
        </p>
      </div>
      <div class="row2 row2-mob">
        <div class="caixa-viagem">
          <p>
            <b>Data e Hora Início Viagem:</b>
            {{ viagem.data_inicio_viagem | dataHora }}
          </p>
          <p><b>KM inicial veículo:</b> {{ viagem.km_inicial }}</p>
        </div>
        <div class="caixa-viagem">
          <p>
            <b>Data e Hora Retorno Viagem:</b>
            {{ viagem.data_retorno_viagem | dataHora }}
          </p>
          <p><b>KM final veículo:</b> {{ viagem.km_final }}</p>
        </div>
      </div>
      <div class="disp-flex">
        <p>
          <b>Houve multa de trânsito?</b>
          {{ viagem.teve_multa ? "Sim" : "Não" }}
        </p>
        <p v-if="viagem.teve_multa == true">
          <b>Valor multa:</b> R${{ viagem.valor_multa }}
        </p>
      </div>
      <template v-if="viagem.passageiros_attributes">
        <hr />
        <h4>Passageiro(s)</h4>
        <div class="row4">
          <div
            v-for="(passageiro, index) in viagem.passageiros_attributes"
            :key="passageiro.id"
          >
            <p>
              <b>Passageiro {{ index + 1 }}: </b>{{ passageiro.nome }}
            </p>
          </div>
        </div>
      </template>
    </div>
    <div class="historico">
      <p><b>Criado Por:</b> {{ viagem.criado_por }}</p>
      <p><b>Atualizado Por:</b> {{ viagem.atualizado_por }}</p>
      <p><b>Data de Criação:</b> {{ viagem.created_at | dataHora }}</p>
      <p><b>Data de Atualização:</b> {{ viagem.updated_at | dataHora }}</p>
    </div>
  </div>
</template>
<script>
import { filterData, filterDataHora } from "../../filters/data";

export default {
  name: "ViagemShowDadosGerais",
  props: {
    viagem: {
      type: Object,
      required: true,
    },
  },
  filters: {
    data: filterData,
    dataHora: filterDataHora,
  },
};
</script>
<style lang="scss" scoped>
.caixa-show {
  background-color: white;
  padding: 1rem;
  p {
    b {
      color: #014403;
    }
  }
  h4 {
    font-family: "Proxima-Semibold";
    font-size: 1.3rem;
    margin: 1rem 0rem;
    color: black;
  }
}
.historico {
  margin-top: 1rem;
  p {
    margin: 0.3rem 0.5rem;
    font-size: 0.9rem;
  }
}
.row2-mob {
  display: grid;
  gap: 1rem 1rem;
}
.caixa-viagem {
  border: #585858 solid 0.1rem;
  border-radius: 0.3rem;
  gap: 1rem;
  padding: 0 1rem;
}
@media screen and (min-width: "50rem") {
  .row2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    p {
      display: grid;
      gap: .5rem;
    }
  }
  .row3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    p {
      display: grid;
      gap: .5rem;
    }
  }
  .row4 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1rem;
    p {
      display: grid;
      gap: .5rem;
    }
  }
  .caixa-viagem {
    display: grid;
    grid-template-columns: 1fr 1fr;
    p {
      display: grid;
      gap: .5rem;
    }
  }
  .disp-flex {
    display: flex;
    gap: 2rem;
  }
}
</style>
