<template>
  <div>
    <div id="preloader">
      <div id="loader"></div>
      <slot><span>Carregando... Aguarde.</span></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppOverlayLoading",
};
</script>
<style lang="scss" scoped>
</style>