import * as RouteNames from "../routeNames";
import ViagemIndex from "../../views/viagem/ViagemIndex";
import ViagemForm from "../../views/viagem/ViagemForm";
import ViagemShow from "../../views/viagem/ViagemShow";
import ViagemListaViagensModal from "../../views/viagem/ViagemListaViagensModal";

//raiz indica se é uma rota que pode ser a inicial para o usuario que logar (normalmente vao ser so os indexes de cada crud)
//colocar as rotas em ordem de prioridade para login (as mais no topo vao ser a tela inicial do usuario se ele tiver permissao)

export default [
  {
    name: RouteNames.VIAGENS,
    component: ViagemIndex,
    path: "/viagens",
    meta:{
      requerPermissao: true,
      raiz: true,
      permissoes: ["viagens"],
    },
    children:[
      {
        name: RouteNames.VIAGEM_LISTA_MODAL,
        component: ViagemListaViagensModal,
        path: "/viagens/lista_modal",
        meta:{
          requerPermissao: true,
          permissoes: ["viagens"],
        },
      },
    ]
  },
  {
    name: RouteNames.VIAGEM_EDIT,
    component: ViagemForm,
    path: "/viagens/:id/edit",
    meta:{
      requerPermissao: true,
      permissoes: ["viagens"],
    },
  },
  {
    name: RouteNames.VIAGEM_NEW,
    component: ViagemForm,
    path: "/viagens/new",
    meta:{
      requerPermissao: true,
      permissoes: ["viagens"],
    },
  },
  {
    name: RouteNames.VIAGEM_SHOW,
    component: ViagemShow,
    path: "/viagens/:id/show",
    meta:{
      requerPermissao: true,
      permissoes: ["viagens"],
    },
  },
];