import axios from "axios";
import axiosAuthenticated from "./AxiosAutenticated";

const queryString = require("query-string");

class ViagemApi {
  cancelTokens = {
    getviagens: null,
    getviagem: null,
    salvarViagem: null,
    excluirViagem: null,
    getStatusViagem: null,
    getMotoristas: null,
    getVeiculos: null,
    getViagemPorAnoMes: null,
    getSetoresSic: null
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async getSetoresSic(nome){
    const CancelTokens = axios.CancelToken;
    const source = CancelTokens.source();

    this.cancelTokens.getSetoresSic = source;

    const {
      data
    } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/viagens/setores_sic_auto_complete?nome=${nome}`, {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getSetoresSic = null;

    return data;
  }

  async getViagemPorAnoMes(mes, ano) {
    const CancelTokens = axios.CancelToken;
    const source = CancelTokens.source();

    this.cancelTokens.getViagemPorAnoMes = source;

    const searchString = queryString.stringify({
      mes,
      ano
    });

    const {
      data
    } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/viagens/viagens_por_mes_ano?${searchString}`, {
        cancelToken: source.token,
      }
    )

    this.cancelTokens.getViagemPorAnoMes = null;

    return data;

  }

  async getMotoristas() {
    const CancelTokens = axios.CancelToken;
    const source = CancelTokens.source();

    this.cancelTokens.getMotoristas = source;

    const {
      data
    } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/viagens/motoristas`, {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getMotoristas = null;

    return data;
  }

  async getVeiculos() {
    const CancelTokens = axios.CancelToken;
    const source = CancelTokens.source();

    this.cancelTokens.getVeiculos = source;

    const {
      data
    } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/viagens/veiculos`, {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getVeiculos = null;

    return data;
  }

  async getStatusViagem() {
    const CancelTokens = axios.CancelToken;
    const source = CancelTokens.source();

    this.cancelTokens.getStatusViagem = source;

    const {
      data
    } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/viagens/status_viagem`, {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getStatusViagem = null;

    return data;
  }

  async getviagens({
    page,
    per_page,
    data_agendamento_previsto_inicio,
    data_agendamento_previsto_fim,
    setor_solicitante,
    destino,
    veiculo_id,
    motorista_id,
    teve_multa,
    status_viagem,
  }) {
    const CancelTokens = axios.CancelToken;
    const source = CancelTokens.source();

    this.cancelTokens.getviagens = source;

    const searchString = queryString.stringify({
      page,
      per_page,
      data_agendamento_previsto_inicio,
      data_agendamento_previsto_fim,
      setor_solicitante,
      destino,
      veiculo_id,
      motorista_id,
      teve_multa,
      status_viagem,
    });

    const {
      data
    } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/viagens?${searchString}`, {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getviagens = null;

    return data;
  }

  async getViagem(id) {
    const CancelTokens = axios.CancelToken;
    const source = CancelTokens.source();

    this.cancelTokens.getviagem = source;

    const {
      data
    } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/viagens/${id}`, {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getViagem = null;
    return data;
  }

  async salvarViagem(viagem) {
    try {
      const CancelTokens = axios.CancelToken;
      const source = CancelTokens.source();

      this.cancelTokens.salvarViagem = source;

      let method;
      let url;

      if (viagem.id) {
        method = axiosAuthenticated.put;
        url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/viagens/${viagem.id}`;
      } else {
        method = axiosAuthenticated.post;
        url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/viagens`;
      }
      const {
        data
      } = await method(url, viagem, {
        cancelToken: source.token,
      });

      this.cancelTokens.salvarViagem = null;
      return data;
    } catch (err) {
      this.cancelTokens.salvarViagem = null;
      throw err;
    }
  }

  async excluirViagem(id) {
    const CancelTokens = axios.CancelToken;
    const source = CancelTokens.source();

    this.cancelTokens.excluirViagem = source;
    const {
      data
    } = await axiosAuthenticated.delete(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/viagens/${id}`, {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.excluirViagem = null;
    return data;
  }
}
export default ViagemApi;