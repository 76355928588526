import axios from "axios";
import axiosAuthenticated from "./AxiosAutenticated";

const queryString = require("query-string");

class MotoristaApi {
  cancelTokens = {
    getMotoristas: null,
    getMotorista: null,
    getTipoCnh: null,
    salvarMotorista: null,
    excluirMotorista: null,
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async getTipoCnh() {
    const CancelTokens = axios.CancelToken;
    const source = CancelTokens.source();

    this.cancelTokens.getTipoCnh = source;

    const { data } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/motoristas/tipo_cnh`,
      {
        cancelToken: source.token,
      }
    );
    this.cancelTokens.getTipoCnh = null;
    return data;
  }

  async getMotoristas({ page, per_page, nome, cpf, cnh }) {
    const CancelTokens = axios.CancelToken;
    const source = CancelTokens.source();

    this.cancelTokens.getMotoristas = source;

    const searchString = queryString.stringify({
      page,
      per_page,
      nome,
      cpf,
      cnh
    });

    const { data } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/motoristas?${searchString}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getMotoristas = null;

    return data;
  }

  async getMotorista(id) {
    const CancelTokens = axios.CancelToken;
    const source = CancelTokens.source();

    this.cancelTokens.getMotorista = source;

    const { data } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/motoristas/${id}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getMotorista = null;
    return data;
  }

  async salvarMotorista(motorista) {
    try {
      const CancelTokens = axios.CancelToken;
      const source = CancelTokens.source();

      this.cancelTokens.salvarMotorista = source;

      let method;
      let url;

      if (motorista.id) {
        method = axiosAuthenticated.put;
        url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/motoristas/${motorista.id}`;
      } else {
        method = axiosAuthenticated.post;
        url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/motoristas`;
      }
      const { data } = await method(url, motorista, {
        cancelToken: source.token,
      });

      this.cancelTokens.salvarMotorista = null;
      return data;
    } catch (err) {
      this.cancelTokens.salvarMotorista = null;
      throw err;
    }
  }

  async excluirMotorista(id) {
    const CancelTokens = axios.CancelToken;
    const source = CancelTokens.source();

    this.cancelTokens.excluirMotorista = source;
    const { data } = await axiosAuthenticated.delete(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/motoristas/${id}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.excluirMotorista = null;
    return data;
  }
}

export default MotoristaApi;
