export const LOGIN_ROUTE = "LOGIN_ROUTE";
export const NO_PERMISSION = "NO_PERMISSION";
export const NAO_ENCONTRADO = "NAO_ENCONTRADO";

//Marcas
export const MARCAS = "MARCAS";
export const MARCA_NEW = "MARCA_NEW";
export const MARCA_EDIT = "MARCA_EDIT";

//Tipos de veículos
export const TIPOS_VEICULO = "TIPOS_VEICULO";
export const TIPO_VEICULO_NEW = "TIPO_VEICULO_NEW"
export const TIPO_VEICULO_EDIT = "TIPO_VEICULO_EDIT"

//Motoristas
export const MOTORISTAS = "MOTORISTAS";
export const MOTORISTA_NEW = "MOTORISTA_NEW";
export const MOTORISTA_EDIT = "MOTORISTA_EDIT";
export const MOTORISTA_SHOW = "MOTORISTA_SHOW";

//Veiculos
export const VEICULOS = "VEICULOS";
export const VEICULO_NEW = "VEICULO_NEW";
export const VEICULO_SHOW = "VEICULO_SHOW";
export const VEICULO_EDIT = "VEICULO_EDIT";

// Agendamento
export const AGENDAMENTOS = "AGENDAMENTOS";

//Viagens
export const VIAGENS = "VIAGENS";
export const VIAGEM_NEW = "VIAGEM_NEW";
export const VIAGEM_LISTA_MODAL = "VIAGEM_LISTA_MODAL";
export const VIAGEM_SHOW = "VIAGEM_SHOW";
export const VIAGEM_EDIT = "VIAGEM_EDIT";