<template>
  <div class="resultados">
    <table>
      <thead>
        <tr>
          <th>Nome</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="marca in marcas" :key="marca.id">
          <td class="topo-card">{{ marca.nome }}</td>
          <td class="acoes">
            <BotaoEditarTabela
              :to="{
                name: MARCA_EDIT,
                params: { id: marca.id },
              }"
            />

            <BotaoDeletarTabela @click="excluir(marca.id)" />
          </td>
        </tr>
        <tr v-if="marcas.length == 0">
          <td class="text-center" colspan="2">Nenhum registro encontrado</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import BotaoEditarTabela from "../botoes/botoes_tabelas_resultados/BotaoEditarTabela.vue";
import BotaoDeletarTabela from "../botoes/botoes_tabelas_resultados/BotaoDeletarTabela.vue";
import { MARCA_EDIT } from "../../router/routeNames";

export default {
  name: "MarcasResultados",
  components: {
    BotaoEditarTabela,
    BotaoDeletarTabela,
  },
  props: {
    marcas: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      MARCA_EDIT,
    };
  },
  methods: {
    excluir(id) {
      this.$emit("excluir-marca", id);
    },
  },
};
</script>
<style lang="scss" scoped>
.resultados{
  table{
    width: 100%;
    border-collapse: collapse;
      border-radius: .2rem;
      overflow: hidden;
    thead{
      background-color: #014403;
      color: white;

      th{
        text-align: left;
    padding: 1rem .5rem;
      }
      .text-center{
        text-align: center;
      }
    }
    tbody{
      td{
        padding: .5rem;
      }
      tr:nth-child(even){
        background-color: white;
      }
      .text-center{
        text-align: center;
      }
      .acoes{
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
      }
    }
  }
}
@media screen and (max-width: 64rem) {
  .resultados {
    table {
      width: 100%;
      tbody {
        background-color: transparent;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: .5rem;
      }
      thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }

      tr {
        // margin: .5rem;
        display: block;
        background-color: white;
        width: 100% !important;
        border-radius: .2rem;
        overflow: hidden;
        td {
          padding: 0rem !important;
          min-height: 1.94rem;
        }
        .acoes{
          display: flex;
          justify-content: center;
        }
        .acoes::before{
          display: none;
        }
      }
      td {
        display: block;
        min-height: 1.3rem;
        margin: 0.3rem 0.5rem;
        img {
          height: 1.5rem;
          margin: 0rem 1rem;
        }
      }
      .topo-card {
        background-color: #014403;
        font-family: "Proxima-Bold";
        text-align: center;
        margin: 0rem 0rem 1rem 0rem;
        font-size: 0.9rem;
        color: white;
        display: flex !important;
        justify-content: center;
        align-items: center;
        a {
          color: white;
          text-decoration: none;
        }
      }
    }
  }
}

@media screen and (max-width: 50rem){
  tbody{
    grid-template-columns: 1fr !important;
  }
}
</style>