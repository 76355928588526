import VueRouter from "vue-router";
import Login from "../views/Login";
import SemPermissao from "../views/SemPermissao";
import NaoEncontrado from "../views/NaoEncontrado";

import * as RouteNames from "./routeNames";
import rotasPrivadas from "./rotas_privadas";
import * as Auth from "../services/Auth";
import getRotaInicial from "../helpers/getRotaInicial";

const intersection = require("lodash.intersection");

const router = new VueRouter({
    routes: [{
            path: "/sessao/autenticar",
            component: Login,
            name: RouteNames.LOGIN_ROUTE,
            alias: "/",
        },
        ...rotasPrivadas,
        {
            path: "/sem-permissao",
            component: SemPermissao,
            name: RouteNames.NO_PERMISSION,
        },
        {
            name: RouteNames.NAO_ENCONTRADO,
            path: "/nao-encontrado",
            component: NaoEncontrado,
        },
        {
            path: "*",
            redirect: (to) => {
                return { name: RouteNames.NAO_ENCONTRADO, query: { url: to.path } };
            },
        },
    ],
    mode: 'history',
    scrollBehavior: function(to) {
        if (to.hash) {
            return { selector: to.hash };
        } else {
            return { x: 0, y: 0 };
        }
    },
});

router.beforeEach((to, from, next) => {
    // se é pra rota de autenticar, mas ja esta logado
    if (to.name === RouteNames.LOGIN_ROUTE && Auth.isLoggedIn()) {
        // ENVIA PARA PRIMEIRA ROTA QUE TEM PERMISSAO
        const rotaInicial = getRotaInicial();
        if (rotaInicial) {
            next({
                name: rotaInicial.name,
            });
        } else {
            // não tem permissao em nenhuma rota
            // ATENCAO, POSSIVELMENTE OCORREU ERRO EM ALGUM LUGAR, NAO DEVERIA TER PASSADO PELO LOGIN DO SGU
            // SEM TER NENHUMA PERMISSAO
            next({
                name: RouteNames.NO_PERMISSION,
            });
        }
    } else if (to.matched.some((record) => record.meta.requerPermissao)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.

        if (!Auth.isLoggedIn()) {
            next({
                name: RouteNames.LOGIN_ROUTE,
                query: { redirect: to.fullPath },
            });
        } else if (
            to.matched.some(
                (record) =>
                intersection(
                    record.meta.permissoes,
                    Auth.getUsuario().funcionalidades
                ).length == 0
            )
        ) {
            // não tem permissao
            next({
                name: RouteNames.NO_PERMISSION,
            });
        } else {
            next();
        }
    } else {
        next(); // make sure to always call next()!
    }
});

// esconde os erros de NavigationDuplicated
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => {
        if (
            err.name !== "NavigationDuplicated" &&
            !err.message.includes("Avoided redundant navigation to current location")
        ) {
            console.error(err);
        }
    });
};

export default router;