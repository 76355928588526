<template>
  <VueSimpleAutocomplete
    :items="setoresSolicitante"
    :selected-item="setorSolicitante"
    @change="searchSetor"
    @value-selected="onSetorSelected"
    :is-loading="loadingSetorSolicitante"
    :get-display-value="(setor) => setor.sigla ? setor.sigla + ' - ' + setor.nome : setor.nome"
    :min-length="2"
    placeholder="Digite para pesquisar"
  ></VueSimpleAutocomplete>
</template>

<script>
import VueSimpleAutocomplete from "vue-simple-autocomplete";
import ViagemApi from "../../services/api/ViagemApi";

export default {
  name: "SetorSolicitanteAutoComplete",
  components: {
    VueSimpleAutocomplete,
  },
  model: {
    prop: "setorSolicitante",
    event: "change",
  },
  props: {
    setorSolicitante: Object,
  },
  data() {
    return {
      setoresSolicitante: [],
      loadingSetorSolicitante: false,
    };
  },
  created() {
    this.viagemApi = new ViagemApi();
  },
  methods: {
    async searchSetor(term) {
      try {
        if (this.viagemApi.cancelTokens.getSetoresSic) {
          this.viagemApi.cancelTokens.getSetoresSic.cancel(
            "Usuário fez outra busca"
          );
        }

        this.setoresSolicitante = [];
        this.$emit("change", {});

        if (!term || term.length === 0) {
          return;
        }
        this.loadingSetorSolicitante = true;
        const data = await this.viagemApi.getSetoresSic(term);

        this.setoresSolicitante = data;

        this.loadingSetorSolicitante = false;
      } catch (err) {
        if (!this.viagemApi.isCancel(err)) {
          this.loadingSetorSolicitante = false;
        }
      }
    },
    onSetorSelected(setor) {
      this.$emit("change", setor);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
