<template>
  <form @submit="submit">
    <div class="caixa-form">
      <div class="row3">
        <div class="item">
          <label>Status do Agendamento:<span>*</span></label>
          <select
            @change="
              (event) => updateValue('status_viagem', event.target.value)
            "
          >
            <option
              v-for="status in statusViagem"
              :key="status.value"
              :value="status.value"
              :selected="status.value === viagem.status_viagem"
            >
              {{ status.label }}
            </option>
          </select>
        </div>
        <div class="item">
          <label>Data e Hora Prevista da Viagem:<span>*</span></label>
          <DatePicker
            v-model="dataAgendamentoPrevisto"
            @change="(value) => updateValue('data_agendamento_previsto', value)"
            format="DD/MM/YYYY H:mm"
            confirm
            type="datetime"
          />
        </div>
        <div class="item">
          <label>Setor Solicitante:<span>*</span></label>
          <SetorSolicitanteAutoComplete
            v-model="viagem.setor_solicitante"
          ></SetorSolicitanteAutoComplete>
        </div>
      </div>
      <div class="item">
        <label>Motivo da Viagem:<span>*</span></label>
        <input
          type="text"
          @input="(event) => updateValue('motivo_viagem', event.target.value)"
          :value="viagem.motivo_viagem"
        />
      </div>
      <div class="row2">
        <div class="item">
          <label>Local de Destino:<span>*</span></label>
          <input
            type="text"
            @input="(event) => updateValue('destino', event.target.value)"
            :value="viagem.destino"
          />
        </div>
        <div class="item">
          <label>Local de Embarque:<span>*</span></label>
          <input
            type="text"
            @input="(event) => updateValue('local_saida', event.target.value)"
            :value="viagem.local_saida"
          />
        </div>
      </div>
      <div class="row2">
        <div class="item">
          <label>Motorista:</label>
          <select
            @change="(event) => updateValue('motorista_id', event.target.value)"
          >
            <option :value="null" :selected="!viagem.motorista_id">
              Selecione
            </option>
            <option
              v-for="motorista in motoristas"
              :key="motorista.value"
              :value="motorista.value"
              :selected="motorista.value === viagem.motorista_id"
            >
              {{ motorista.label }}
            </option>
          </select>
        </div>
        <div class="item">
          <label>Veículo:</label>
          <select
            @change="(event) => updateValue('veiculo_id', event.target.value)"
          >
            <option :value="null" :selected="!viagem.veiculo_id">
              Selecione
            </option>
            <option
              v-for="veiculo in veiculos"
              :key="veiculo.value"
              :value="veiculo.value"
              :selected="veiculo.value === viagem.veiculo_id"
            >
              {{ veiculo.label }}
            </option>
          </select>
        </div>
      </div>
      <div class="row2">
        <div class="caixa-dados">
          <div class="item">
            <label>Data e Hora Início Viagem:</label>
            <DatePicker
              v-model="dataInicioViagem"
              @change="(value) => updateValue('data_inicio_viagem', value)"
              format="DD/MM/YYYY H:mm"
              confirm
              type="datetime"
            />
          </div>
          <div class="item">
            <label>KM inicial veículo:</label>
            <input
              type="text"
              @input="(event) => updateValue('km_inicial', event.target.value)"
              :value="viagem.km_inicial"
            />
          </div>
        </div>
        <div class="caixa-dados">
          <div class="item">
            <label>Data e Hora Retorno Viagem:</label>
            <DatePicker
              v-model="dataRetornoViagem"
              @change="(value) => updateValue('data_retorno_viagem', value)"
              format="DD/MM/YYYY H:mm"
              confirm
              type="datetime"
            />
          </div>
          <div class="item">
            <label>KM final veículo:</label>
            <input
              type="text"
              @input="(event) => updateValue('km_final', event.target.value)"
              :value="viagem.km_final"
            />
          </div>
        </div>
      </div>
      <div class="row2-flex">
        <div class="item">
          <div class="checkbox">
            <label class="check path" for="teve_multa">
              <input
                id="teve_multa"
                name="teve_multa"
                type="checkbox"
                @change="
                  (event) => {
                    updateValueCheckbox(event);
                  }
                "
                :value="viagem.teve_multa"
                :checked="viagem.teve_multa"
              />
              <svg viewBox="0 0 21 21">
                <path
                  d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"
                ></path>
              </svg>
              <span> Houve multa de trânsito? </span>
            </label>
          </div>
        </div>
        <div class="item" v-if="viagem.teve_multa">
          <label>Valor multa:</label>
          <Money
            @change="(event) => updateValue('valor_multa', event.target.value)"
            v-model="valor_multa_mascarado"
            v-bind="dinheiro"
          ></Money>
        </div>
      </div>
      <ViagemPassageirosFormulario
        v-model="viagem.passageiros_attributes"
      ></ViagemPassageirosFormulario>
    </div>
    <div class="botao-salvar">
      <BotaoSalvar v-if="!salvando" />
      <AppLoading v-else size="small" texto="" />
    </div>
  </form>
</template>
<script>
import BotaoSalvar from "../botoes/BotaoSalvar";
import AppLoading from "../../components/AppLoading";
import DatePicker from "vue2-datepicker";
import { parseISO } from "date-fns";
import "vue2-datepicker/index.css";
import ViagemPassageirosFormulario from "./ViagemPassageirosFormulario";
import { Money } from "v-money";
import SetorSolicitanteAutoComplete from "../../components/autocompletes/SetorSolicitanteAutoComplete.vue";

export default {
  name: "ViagemFormulario",
  components: {
    BotaoSalvar,
    DatePicker,
    AppLoading,
    ViagemPassageirosFormulario,
    Money,
    SetorSolicitanteAutoComplete,
  },
  model: {
    prop: "viagem",
    event: "update-viagem",
  },
  props: {
    viagem: {
      type: Object,
      required: true,
    },
    statusViagem: {
      type: Array,
      required: true,
    },
    setoresSolicitante: {
      type: Array,
      required: true,
    },
    veiculos: {
      type: Array,
      required: true,
    },
    motoristas: {
      type: Array,
      required: true,
    },
    salvando: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataAgendamentoPrevisto:
        this.viagem.data_agendamento_previsto &&
        typeof this.viagem.data_agendamento_previsto == "string"
          ? parseISO(this.viagem.data_agendamento_previsto)
          : this.viagem.data_agendamento_previsto,
      dataInicioViagem:
        this.viagem.data_inicio_viagem &&
        typeof this.viagem.data_inicio_viagem == "string"
          ? parseISO(this.viagem.data_inicio_viagem)
          : this.viagem.data_inicio_viagem,
      dataRetornoViagem:
        this.viagem.data_retorno_viagem &&
        typeof this.viagem.data_retorno_viagem == "string"
          ? parseISO(this.viagem.data_retorno_viagem)
          : this.viagem.data_retorno_viagem,
      dinheiro: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false,
      },
      valor_multa_mascarado: this.viagem.valor_multa
        ? this.viagem.valor_multa
        : 0,
    };
  },
  watch: {
    valor_multa_mascarado: function (newValue) {
      this.updateValue("valor_multa", newValue);
    },
    dataAgendamentoPrevisto: function (newValue) {
      this.dataAgendamentoPrevisto = newValue;
    },
    dataInicioViagem: function (newValue) {
      this.dataInicioViagem = newValue;
    },
    dataRetornoViagem: function (newValue) {
      this.dataRetornoViagem = newValue;
    },
  },
  methods: {
    submit(e) {
      this.$emit("submit", e);
    },
    updateValue(key, value) {
      this.$emit("update-viagem", {
        ...this.viagem,
        [key]: value,
      });
    },
    updateValueCheckbox(event) {
      const { checked, name } = event.srcElement;
      this.updateValue(name, checked);
    },
  },
};
</script>
<style lang="scss" scoped>
.caixa-form {
  background-color: white;
  padding: 0.5rem 1rem;
}

.item-flex {
  display: flex;
  align-items: center;
  margin-bottom: -1rem;
  label {
    font-family: "Proxima-Regular";
    margin-bottom: 0.2rem;
  }
  input {
    height: 1.5rem;
    font-size: 1rem;
    margin-left: 0.5rem;
  }
}
.item {
  display: grid;
  margin: 1rem 0;
  gap: 0.5rem;
  label {
    font-family: "Proxima-regular";
    margin-bottom: 0.2rem;
  }
  span {
    color: red;
  }
  input {
    height: 1.5rem;
    width: 100%;
    font-size: 1rem;
    width: calc(100% - 0.5rem);
  }
  select {
    height: 2rem;
    font-size: 1rem;
    width: 100%;
  }
  .mx-datepicker {
    width: 100%;
  }

  .checkbox {
    margin-top: 0.5rem;
    input {
      width: auto;
    }
    span {
      color: black;
      font-family: "Proxima-Regular";
    }
    .check {
      --background: #fff;
      --border: #d1d6ee;
      --border-hover: #bbc1e1;
      --border-active: #1b5138;
      --tick: #fff;
      position: relative;
      cursor: pointer;
      input,
      svg {
        width: 21px;
        height: 21px;
        display: inline-block;
      }
      input {
        -webkit-appearance: none;
        -moz-appearance: none;
        position: relative;
        outline: none;
        background: var(--background);
        border: none;
        margin: 0;
        transform: translateY(5px);
        padding: 0;
        cursor: pointer;
        border-radius: 4px;
        transition: box-shadow 0.3s;
        box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));
        &:hover {
          --s: 2px;
          --b: var(--border-hover);
        }
        &:checked {
          --b: var(--border-active);
        }
      }
      svg {
        pointer-events: none;
        fill: none;
        stroke-width: 2px;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke: var(--stroke, var(--border-active));
        position: absolute;
        top: 0;
        left: 0;
        width: 21px;
        height: 21px;
        transform: scale(var(--scale, 1)) translateZ(1);
        margin-top: -0.2rem;
      }
      span {
        display: inline;
        margin-left: 0rem;
      }
      &.path {
        input {
          &:checked {
            --s: 2px;
            transition-delay: 0.4s;
            & + svg {
              --a: 16.1 86.12;
              --o: 102.22;
            }
          }
        }
        svg {
          stroke-dasharray: var(--a, 86.12);
          stroke-dashoffset: var(--o, 86.12);
          transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
        }
      }
    }
  }
}
@media screen and(min-width: '51em') {
  .row3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    margin: 1.5rem 0;
  }
  .item {
    margin: 0;
  }
  .row2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin: 1.5rem 0;
  }
  .row2-flex {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin: 1.5rem 0;
  }
  .caixa-dados {
    border: rgb(99, 99, 99) solid 0.1rem;
    // background: #efefef;
    padding: 0.5rem;
    border-radius: 0.3rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    .item {
      margin: 1.5rem 0;
    }
  }
}
</style>
