<template>
  <div class="the-menu">
    <nav>
      <div class="centro">
        <span>{{version}}</span>
        <div class="direita">
          <span>{{ nome }}</span>
          <a @click="logout"><IconSair /></a>
        </div>
      </div>
    </nav>
    <div class="barra-menu centro">
      <div class="logo-menu">
        <img src="../../assets/images/logo-frotas2.png" alt="" />
      </div>

      <input class="checkmenu" id="atv-menu" type="checkbox" />
      <label for="atv-menu">
        <div class="menu">
          <span class="hamburger"></span>
        </div>
      </label>
      <div class="lista-menu">
        <div class="centro">
          <ul>
            <li v-for="menuItem in menu" :key="menuItem.label">
              <template v-if="menuItem.routeName">
                <router-link :to="{ name: menuItem.routeName }">
                  <component :is="menuItem.icon" v-if="menuItem.icon" />
                  {{ menuItem.label }}
                </router-link>
              </template>
              <template v-else>
                <a class="a-menu" @click="toggleClass">
                  <span> <component :is="menuItem.icon" v-if="menuItem.icon" />
                  {{ menuItem.label }} </span> <IconSetaMenu class="seta-menu" />
                </a>
                <ol>
                  <li
                    v-for="subItem in menuItem.routeList"
                    :key="subItem.label"
                  >
                    <router-link :to="{ name: subItem.routeName }">
                      {{ subItem.label }}
                    </router-link>
                  </li>
                </ol>
              </template>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { LOGIN_ROUTE } from "../../router/routeNames";
import * as Auth from "../../services/Auth";

import IconSair from "../../assets/images/icon-sair.svg";
import IconSetaMenu from "../../assets/images/icon-seta-menu.svg";

import menu from "../../router/menu";

const get = require("lodash.get");

export default {
  name: "TheMenu",
  components: {
    IconSair,
    IconSetaMenu,
  },
  data() {
    const usuario = Auth.getUsuario();

    const filtrarRotas = (item) => {
      if (item.routeName) {
        const route = this.$router.options.routes.find(
          (route) => route.name == item.routeName
        );
        const requerPermissao = get(route, "meta.requerPermissao", false);
        const permissoes = get(route, "meta.permissoes", []);

        if (
          route &&
          (!requerPermissao ||
            permissoes.every((permissao) =>
              usuario.funcionalidades.includes(permissao)
            ))
        ) {
          return item;
        }
        return null;
      } else if (item.routeList) {
        const itemFiltrado = {
          ...item,
          routeList: item.routeList.reduce((acc, route) => {
            const rotaFiltrada = filtrarRotas(route);

            if (rotaFiltrada) return acc.concat(rotaFiltrada);

            return acc;
          }, []),
        };

        if (
          itemFiltrado &&
          itemFiltrado.routeList &&
          itemFiltrado.routeList.length > 0
        )
          return itemFiltrado;

        return null;
      }
      return null;
    };

    let menuPermitido = menu.reduce((accumulator, element) => {
      const rotaFiltrada = filtrarRotas(element);

      if (rotaFiltrada && (rotaFiltrada.routeName || rotaFiltrada.routeList))
        return accumulator.concat(rotaFiltrada);

      return accumulator;
    }, []);

    return {
      menu: menuPermitido,
      version: process.env.VUE_APP_VERSION
    };
  },
  watch: {
    "$route.name": function () {
      //se rota muda, tenho que remover a classe menu-aberto dos links e fechar o menu
      this.fecharMenu();
    },
  },
  computed: {
    nome() {
      if (Auth.getUsuario()) {
        return Auth.getUsuario().nome;
      }
      return null;
    },
  },
  methods: {
    logout() {
      Auth.logout();
      this.$router.replace({ name: LOGIN_ROUTE });
    },
    toggleClass(event) {
      if (event.target.nodeName.toUpperCase() === "A") {
        event.srcElement.classList.toggle("menu-aberto");
        if (event.target.href && event.target.href != "") {
          this.fecharMenu();
        }
      } else if (
        event.target.parentNode &&
        event.target.parentNode.nodeName.toUpperCase() === "A"
      ) {
        event.target.parentElement.classList.toggle("menu-aberto");
      }
    },
    removeClassMenuAberto() {
      const linksAbertos = document.getElementsByClassName("menu-aberto");
      while (linksAbertos && linksAbertos.length > 0) {
        linksAbertos[0].classList.remove("menu-aberto");
      }
    },
    fecharMenu() {
      const menu = document.getElementById("atv-menu");
      if (menu) {
        menu.checked = false;
        this.removeClassMenuAberto();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.the-menu {
  position: fixed;
  width: 100%;
  top: 0;
  background-color: white;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.521);
  z-index: 2000;
}
nav {
  background: #014403;
  padding: 0.3rem 0.5rem 0.3rem;
  .centro {
    color: white;
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
  }
  .direita {
    display: flex;
    align-items: center;
  }
  svg {
    height: 0.8rem;
    width: 0.8rem;
    margin-left: 0.5rem;
    cursor: pointer;
  }
}
.barra-menu {
  padding: 1rem 0.5rem;
  background-color: white;
  position: relative;
  .logo-menu {
    text-align: center;
    img {
      height: 3rem;
    }
  }
  .menu {
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    position: fixed;
    top: 3rem;
    right: 0.5rem;
    z-index: 100;

    .hamburger {
      display: block;
      background-color: #ffaa0f;
      width: 1.4rem;
      height: 2px;
      position: relative;
      z-index: 12;
      margin: 1rem auto;
    }

    .hamburger::before,
    .hamburger::after {
      background-color: #ffaa0f;
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      transition: 0.5s ease-in-out;
    }

    .hamburger::before {
      top: 0.4em;
    }

    .hamburger::after {
      bottom: 0.35em;
    }
  }

  .checkmenu {
    display: none;
  }
  input:checked ~ label {
    .hamburger {
      transform: rotate(45deg);
    }
    .hamburger::before,
    .hamburger::after {
      transform: rotate(90deg);
      top: 0;
    }
  }
  input:checked ~ .lista-menu {
    left: 0;
    a {
      left: 0;
    }
  }
  .lista-menu {
    position: absolute;
    background-color: #08730b;
    height: calc(100vh - 6.5rem);
    width: 100%;
    left: -120%;
    top: 5.15rem;
    transition: 0.2s;
    ul,
    ol {
      margin: 0;
      list-style: none;
      padding: 0;
    }
    ul {
      li {
        height: 3rem;
        a {
          height: 100%;
          display: flex;
          align-items: center;
          text-decoration: none;
          color: white;
          font-family: "Proxima-Regular";
          border-bottom: #1a5e1d solid 0.05rem;
          svg{
            fill: rgba(255, 255, 255, 0.616);
            height: 1.8rem;
            width: 1.8rem;
            margin-right: 1rem;
          }
        }
        .a-menu {
          display: flex;
          justify-content: space-between;
          padding-right: 1rem;
          cursor: pointer;
          span{
            display: flex;
            align-items: center;
          font-family: "Proxima-Regular";
          }
          .seta-menu {
            height: 0.7rem;
            fill: white;
            transform: rotate(-90deg);
            transition: 0.2s;
            margin-right: 0;
          }
        }
        ol {
          height: 0;
          overflow: hidden;
          opacity: 0;
          transition: 0.2s;
        }
        .menu-aberto ~ ol {
          height: auto;
          opacity: 1;
          a {
            padding-left: 1rem;
          }
        }
        .menu-aberto {
          svg.seta-menu {
            transform: rotate(0deg);
          }
        }
      }
    }
  }
}

// @media screen and (min-width: "40em") {
//   .the-menu {
//     .barra-menu {
//       display: flex;
//       justify-content: space-between;
//       .logo-menu {
//         text-align: left;
//       }
//       .menu {
//         display: none;
//       }
//       .lista-menu {
//         position: static;
//         height: auto;
//         display: grid;
//         grid-template-columns: auto auto auto auto;
//         gap: 2rem;
//         min-height: auto;
//         margin-left: 3rem;
//         margin-top: 0;
//         background-color: transparent;
//         width: auto;
//         a {
//           flex-direction: column;
//           height: auto;
//           color: black;
//           justify-content: center;
//           margin-bottom: -1rem;
//           margin-top: -0.5rem;
//           background-color: transparent;
//           box-shadow: none;
//           transition: 0.2s ease-in-out;
//           svg {
//             margin: 0 0 0.5rem;
//             fill: #08730b;
//             transition: 0.2s ease-in-out;
//             height: 1.7rem;
//           }
//         }
//         a:hover:after {
//           content: "";
//           display: block;
//           height: 0.5rem;
//           width: 0.2rem;
//           border-radius: 0.1rem;
//           background-color: #ffaa0f;
//           position: absolute;
//           bottom: 0;
//         }
//       }
//     }
//   }
// }
</style>