import * as RouteNames from "../routeNames";
import VeiculoIndex from "../../views/veiculo/VeiculoIndex";
import VeiculoForm from "../../views/veiculo/VeiculoForm";
import VeiculoShow from "../../views/veiculo/VeiculoShow";

//raiz indica se é uma rota que pode ser a inicial para o usuario que logar (normalmente vao ser so os indexes de cada crud)
//colocar as rotas em ordem de prioridade para login (as mais no topo vao ser a tela inicial do usuario se ele tiver permissao)

export default [
  {
    name: RouteNames.VEICULOS,
    component: VeiculoIndex,
    path: "/veiculos",
    meta:{
      requerPermissao: true,
      raiz: true,
      permissoes: ["veiculos"],
    },
  },
  {
    name: RouteNames.VEICULO_EDIT,
    component: VeiculoForm,
    path: "/veiculos/:id/edit",
    meta:{
      requerPermissao: true,
      permissoes: ["veiculos"],
    },
  },
  {
    name: RouteNames.VEICULO_NEW,
    component: VeiculoForm,
    path: "/veiculos/new",
    meta:{
      requerPermissao: true,
      permissoes: ["veiculos"],
    },
  },
  {
    name: RouteNames.VEICULO_SHOW,
    component: VeiculoShow,
    path: "/veiculos/:id/show",
    meta:{
      requerPermissao: true,
      permissoes: ["veiculos"],
    },
  },
];