<template>
  <div class="centro">
    <div class="parte1">
      <div class="esquerda">
        <router-link :to="{ name: VEICULOS, query: { ...$route.query } }">
          <IconVoltar />
        </router-link>
        <h1>{{ $route.params.id ? "Editar" : "Novo" }} Veículo</h1>
      </div>
      <BotaoVisualizarForm
        v-if="$route.params.id"
        :to="{ name: VEICULO_SHOW, params: { id: $route.params.id } }"
      />
    </div>
    <AppErrosFormularios
      v-if="erroSalvar && erroSalvar.length > 0"
      :erros="erroSalvar"
    />
    <div id="erro-container">
      <AppErro v-if="erroCarregar && !carregando"></AppErro>
    </div>
    <VeiculosFormulario
      @submit.prevent="salvar"
      v-if="!carregando && !erroCarregar"
      :tipos-combustivel="tiposCombustivel"
      v-model="veiculo"
      :salvando="salvando"
    />
  </div>
</template>
<script>
import VeiculoApi from "../../services/api/VeiculosApi";
import { VEICULOS, VEICULO_SHOW } from "../../router/routeNames";
import VeiculosFormulario from "../../components/veiculos/VeiculosFormulario";
import BotaoVisualizarForm from "../../components/botoes/BotaoVisualizarForm";
import IconVoltar from "../../assets/images/icon-voltar.svg";
import AppErro from "../../components/AppErro";
import AppErrosFormularios from "../../components/AppErrosFormularios";

export default {
  name: "VeiculoForm",
  components: {
    VeiculosFormulario,
    BotaoVisualizarForm,
    IconVoltar,
    AppErro,
    AppErrosFormularios,
  },
  metaInfo() {
    return {
      title: this.$route.params.id ? "Editar Veículo" : "Nova Veículo",
      titleTemplate: "%s - Frotas",
    };
  },
  data() {
    return {
      VEICULOS,
      VEICULO_SHOW,
      tiposCombustivel: [],
      veiculo: {
        id: null,
        placa: "",
        modelo: "",
        marca: {},
        tipo_veiculo: {},
        veiculo_proprio: false,
        data_vencimento_ipva: null,
        ano_veiculo: null,
        tipo_combustivel: null,
      },
      carregando: false,
      salvando: false,
      erroCarregar: false,
      erroSalvar: null,
    };
  },
  created() {
    this.veiculoApi = new VeiculoApi();
    this.carregarTiposCombustivel();
    if (this.$route.params.id) {
      this.carregarVeiculo();
    }
  },
  methods: {
    async carregarTiposCombustivel() {
      try {
        this.erroCarregar = false;
        this.carregando = true;
        this.tiposCombustivel = await this.veiculoApi.getTiposCombustivel();
        this.carregando = false;
      } catch (error) {
        console.log(error);
        this.erroCarregar = true;
        this.carregando = false;
      }
    },
    async salvar() {
      try {
        this.salvando = true;
        this.erroSalvar = null;

        const data = await this.veiculoApi.salvarVeiculo({
          ...this.veiculo,
          tipo_veiculo_id: this.veiculo.tipo_veiculo.id,
          marca_id: this.veiculo.marca.id,
        });

        this.$emit("on-reload-data");
        this.salvando = false;

        this.$swal({
          toast: true,
          icon: "success",
          position: "top-end",
          title: "Veículo salvo com sucesso!",
          timer: 3000,
          showConfirmButton: false,
        });

        this.$router.push({
          name: VEICULO_SHOW,
          params: { id: data.id },
        });
      } catch (err) {
        this.salvando = false;

        let data;
        if (err.response) {
          data = err.response.data;
        }

        if (data) {
          if (Array.isArray(data)) {
            this.erroSalvar = data;
          } else if (data.mensagem) {
            this.erroSalvar = [data.mensagem];
          } else {
            this.erroSalvar = [
              "Houve um erro ao salvar os dados. Por favor, tente novamente",
            ];
          }
        } else {
          this.erroSalvar = [
            "Houve um erro ao salvar os dados. Por favor, tente novamente",
          ];
        }
        this.$scrollTo("#erro-container", {
          offset: -200,
        });
      }
    },
    async carregarVeiculo() {
      try {
        this.carregando = true;
        this.erroCarregar = false;

        const data = await this.veiculoApi.getVeiculo(this.$route.params.id);
        this.veiculo = data;

        this.carregando = false;
      } catch (err) {
        console.log(err);
        this.carregando = false;
        this.erroCarregar = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.parte1 {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  .esquerda {
    display: flex;
    align-items: center;
    gap: 1rem;
  h1 {
    font-family: "Proxima-Bold";
    color: #014403;
    margin: 0;
  }
  }
}
</style>