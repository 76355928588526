<template>
  <div>
    <TheMenuMobile class="menu-mobile"/>
    <TheMenuDesk class="menu-desk"/>
  </div>
</template>

<script>
import TheMenuMobile from "./menu/TheMenuMobile.vue";
import TheMenuDesk from "./menu/TheMenuDesk.vue";

export default {
  name: "TheMenu",
  components:{
    TheMenuMobile,
    TheMenuDesk,
  },
}
</script>

<style lang="scss" scoped>
.menu-desk{
  display: none;
}

@media screen and(min-width: '45em') {
  .menu-desk{
    display: block;
  }
  .menu-mobile{
    display: none;
  }
  
}
</style>