<template>
  <div class="resultados">
    <table>
      <thead>
        <tr>
          <th>Placa</th>
          <th>Modelo</th>
          <th>Marca</th>
          <th class="text-center">Tipo do Veículo</th>
          <th class="text-center">Veículo Proprio?</th>
          <th class="text-center">Data de venc. do IPVA</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="veiculo in veiculos" :key="veiculo.id">
          <td class="topo-card">{{ veiculo.placa }}</td>
          <td data-label="Modelo:">{{ veiculo.modelo }}</td>
          <td data-label="Marca:">{{ veiculo.marca.nome }}</td>
          <td class="text-center" data-label="Tipo do Veículo:">
            {{ veiculo.tipo_veiculo.nome }}
          </td>
          <td class="text-center" data-label="Veículo Próprio?">
            {{ veiculo.veiculo_proprio ? "Sim" : "Não" }}
          </td>
          <td class="text-center" data-label="Data de venccimento do IPVA:">
            {{ veiculo.data_vencimento_ipva | dataFormat }}
          </td>
          <td class="acoes">
            <BotaoEditarTabela
              :to="{
                name: VEICULO_EDIT,
                params: { id: veiculo.id },
              }"
            />
            <BotaoExibirTabela
              :to="{
                name: VEICULO_SHOW,
                params: { id: veiculo.id },
              }"
            />
            <BotaoDeletarTabela @click="excluir(veiculo.id)" />
          </td>
        </tr>
        <tr v-if="veiculos.length == 0">
          <td class="text-center" colspan="7">Nenhum registro encontrado</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import BotaoExibirTabela from "../botoes/botoes_tabelas_resultados/BotaoExibirTabela.vue";
import BotaoEditarTabela from "../botoes/botoes_tabelas_resultados/BotaoEditarTabela.vue";
import BotaoDeletarTabela from "../botoes/botoes_tabelas_resultados/BotaoDeletarTabela.vue";
import { VEICULO_SHOW, VEICULO_EDIT } from "../../router/routeNames";
import { filterData } from "../../filters/data";

export default {
  name: "VeiculoResultados",
  components: {
    BotaoExibirTabela,
    BotaoEditarTabela,
    BotaoDeletarTabela,
  },
  props: {
    veiculos: {
      type: Array,
      default: () => [],
    },
  },
  filters: {
    dataFormat: filterData,
  },
  data() {
    return {
      VEICULO_SHOW,
      VEICULO_EDIT,
    };
  },
  methods: {
    excluir(id) {
      this.$emit("excluir-veiculo", id);
    },
  },
};
</script>
<style lang="scss" scoped>
.resultados {
  table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 0.2rem;
    overflow: hidden;
    thead {
      background-color: #014403;
      color: white;

      th {
        text-align: left;
    padding: 1rem .5rem;
      }
      .text-center {
        text-align: center;
      }
    }
    tbody {
      td {
        padding: 0.5rem;
      }
      tr:nth-child(even) {
        background-color: white;
      }
      .text-center {
        text-align: center;
      }
      .acoes {
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
      }
    }
  }
}
@media screen and (max-width: 64rem) {
  .resultados {
    table {
      width: 100%;
      tbody {
        background-color: transparent;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0.5rem;
      }
      td.text-center {
        text-align: left;
      }
      thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }

      tr {
        // margin: .5rem;
        display: block;
        background-color: white;
        width: 100% !important;
        border-radius: 0.2rem;
        overflow: hidden;
        td {
          padding: 0rem !important;
          min-height: 1.94rem;
        }
        .acoes {
          display: flex;
          justify-content: center;
        }
        .acoes::before{
          display: none;
        }
      }
      td {
        display: block;
        min-height: 1.3rem;
        margin: 0.3rem 0.5rem;
        img {
          height: 1.5rem;
          margin: 0rem 1rem;
        }
      }

      td::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        color: #054700;
        font-family: "Proxima-SemiBold";
        margin-right: 0.5rem;
      }
      .topo-card {
        background-color: #014403;
        font-family: "Proxima-Bold";
        text-align: center;
        margin: 0rem 0rem 1rem 0rem;
        font-size: 0.9rem;
        color: white;
        display: flex !important;
        justify-content: center;
        align-items: center;
        a {
          color: white;
          text-decoration: none;
        }
      }
    }
  }
}

@media screen and (max-width: 50rem) {
  tbody {
    grid-template-columns: 1fr !important;
  }
}
</style>