<template>
  <div class="tela-modal">
    <div class="caixa-modal">
      <AppLoading v-if="carregando" />
      <AppErro v-if="erroCarregar && !carregando" />
      <template v-if="!carregando && !erroCarregar">
        <router-link class="lk-fechar" :to="{ name: VIAGENS }">✖</router-link>
        <h4>{{ dataRefForm }}</h4>
        <div class="row2">
          <div
            class="item-card"
            v-for="viagem in listaViagens"
            :key="viagem.id"
          >
            <span>
              <span class="horario"
                >{{ horaFormatada(viagem.data_agendamento_previsto) }} -
              </span>
              <span>{{
                viagem.motorista ? primeiroNome(viagem.motorista.nome) : ""
              }}</span>
            </span>
            <span><b>Solicitante:</b> {{ viagem.setor_solicitante }}</span>
            <span><b>Destino: </b>{{ viagem.destino }} </span>
            <router-link
              :to="{
                name: VIAGEM_SHOW,
                params: { id: viagem.id },
              }"
              ><IconView /> Visualizar
            </router-link>
            <button class="botao-delete" title="Excluir item" type="button" @click="excluirViagem(viagem.id)">
              <IconDelete /> 
            </button>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { format, parseISO } from "date-fns";
import { VIAGEM_SHOW, VIAGENS } from "../../router/routeNames";
import IconView from "../../assets/images/icon-view.svg";
import ViagemApi from "../../services/api/ViagemApi";
import AppErro from "../../components/AppErro";
import IconDelete from "../../assets/images/icon-delete.svg";
import AppLoading from "../../components/AppLoading";
import pt from "date-fns/locale/pt";

export default {
  name: "ViagemListaViagensModal",
  components: {
    IconView,
    AppLoading,
    IconDelete,
    AppErro,
  },
  metaInfo() {
    return {
      title: "Lista de viagens",
      titleTemplate: "%s - Frotas",
    };
  },
  created() {
    this.viagemApi = new ViagemApi();
    this.carregarDados();
  },
  data() {
    return {
      VIAGEM_SHOW,
      VIAGENS,
      listaViagens: [],
      carregando: false,
      excluindo: false,
      dataRefForm: format(
        new Date(
          this.$route.query.anoRef,
          this.$route.query.mesRef,
          this.$route.query.diaRef
        ),
        "EEEE, dd 'de' MMMM 'de' yyyy",
        { locale: pt }
      ),
      erroCarregar: false,
    };
  },
  methods: {
    async carregarDados() {
      try {
        this.carregando = true;
        this.erroCarregar = false;

        let data_agendamento_previsto_inicio = new Date(
          this.$route.query.anoRef,
          this.$route.query.mesRef,
          this.$route.query.diaRef
        );

        let data_agendamento_previsto_fim = new Date(
          this.$route.query.anoRef,
          this.$route.query.mesRef,
          this.$route.query.diaRef,
          23,
          59,
          59
        );

        const data = await this.viagemApi.getviagens({
          data_agendamento_previsto_inicio,
          data_agendamento_previsto_fim,
        });

        this.listaViagens = data.resultados;

        this.TipoVeiculoForm = data;

        this.carregando = false;
      } catch (err) {
        console.log(err);
        this.carregando = false;
        this.erroCarregar = true;
      }
    },
    horaFormatada(data) {
      return format(parseISO(data), "H:mm");
    },
    primeiroNome(nome) {
      if (nome) return nome.split(" ")[0];
      return "";
    },
    async excluirViagem(id) {
      try {
        const result = await this.$swal.fire({
          title: "Você tem certeza?",
          text: "Você não poderá desfazer essa ação",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        });

        if (!result.isConfirmed) {
          return;
        }

        this.excluindo = true;

        await this.viagemApi.excluirViagem(id);
        this.excluindo = false;

        this.$swal({
          icon: "success",
          position: "top-end",
          toast: true,
          title: "Viagem excluído com sucesso!",
          timer: 3000,
          showConfirmButton: false,
        });

        this.carregarDados();
      } catch (err) {
        console.log(err);
        this.excluindo = false;
        this.$swal({
          icon: "error",
          title:
            "Não foi possível excluir o registro. Por favor tente novamente.",
          showConfirmButton: true,
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.tela-modal {
  position: fixed;
  z-index: 2001;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.426);
  display: flex;
  justify-content: center;
  align-items: center;
  .caixa-modal {
    background-color: white;
    padding: 1rem;
    width: 80%;
    max-width: 45rem;
    position: relative;
    .lk-fechar {
      position: absolute;
      top: 0em;
      right: 1em;
      text-decoration: none;
      color: slategray;
      transition: 0.2s;
    }
    .lk-fechar:hover {
      color: red;
    }
    h4 {
      margin: 0 0 1rem;
      font-family: "Proxima-Bold";
      color: #014403;
      font-size: 1.4rem;
    }
    .row2 {
      display: grid;
      grid-template-columns: 1fr;
      gap: 1rem;
    }
    .horario {
      font-family: "Proxima-Bold";
    }
    .item-card {
      display: grid;
      gap: 0.5rem;
      padding: 1rem;
      background-color: #efefef;
      position: relative;
      span {
        b {
          font-family: "Proxima-Bold";
        }
      }
      .botao-delete{
        border: none;
        background-color: transparent;
        position: absolute;
        right: .5rem;
        top: .8rem;
        cursor: pointer;
        svg{
          fill: red;
          height: 1.2rem;
          width: 1.5rem;
        }
      }
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #014403;
      text-decoration: none;
      margin-top: 1rem;
      svg {
        height: 0.8rem;
        width: 2rem;
        fill: #014403;
      }
    }
  }
}
@media screen and (min-width: "50rem") {
  .row2 {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
    gap: 1rem;
  }
}
</style>