<template>
  <div class="grupo-botao">
    <template v-for="viagem in viagemExibir">
      <div :key="viagem.id">
        <div
          class="botao"
          :key="viagem.id"
          @click="$emit('chamar-show', viagem.id)"
        >
          <div class="disp-flex">
            <span class="hora">
              {{ horaFormatada(viagem.data_agendamento_previsto) }} -
            </span>
            <span class="setor"> {{ viagem.setor_solicitante_sigla }}</span>
          </div>
          <span class="motorista">
            <IconMotorista />
            {{ primeiroNome(viagem.nome_motorista) }}
          </span>
          <span class="destino"><IconLocal />{{ viagem.destino }} </span>
        </div>
      </div>
    </template>

    <div class="lk-dia" v-if="isDiaAtual">
      <span class="sem-viagens" v-if="viagemExibir.length == 0">
        Nenhuma viagem nas próximas horas
      </span>
      <template v-if="viagem.viagens_do_dia.length > viagemExibir.length">
        <router-link
          :to="{
            name: VIAGEM_LISTA_MODAL,
            query: {
              diaRef: viagem.dia_agendamento_previsto,
              mesRef: mesReferente,
              anoRef: anoReferente,
            },
          }"
        >
          <Iconview />
          {{
            viagemExibir.length > 0
              ? `Ver Mais ${viagem.viagens_do_dia.length - viagemExibir.length}`
              : "Mostrar Anteriores"
          }}
        </router-link></template
      >
    </div>
    <div class="lk-dia" v-else-if="viagem.viagens_do_dia.length > valorDeCorte">
      <router-link
        :to="{
          name: VIAGEM_LISTA_MODAL,
          query: {
            diaRef: viagem.dia_agendamento_previsto,
            mesRef: mesReferente,
            anoRef: anoReferente,
          },
        }"
      >
        <Iconview />
        {{
          viagemExibir.length > 0
            ? `Ver Mais ${viagem.viagens_do_dia.length - valorDeCorte}`
            : "Mostrar Anteriores"
        }}
      </router-link>
    </div>
  </div>
</template>
<script>
import { format, parseISO } from "date-fns";
import { VIAGEM_LISTA_MODAL } from "../../router/routeNames";
import Iconview from "../../assets/images/icon-view.svg";
import IconLocal from "../../assets/images/icon-local.svg";
import IconMotorista from "../../assets/images/icon-motorista.svg";

export default {
  name: "ViagensResultadosCalendarioViagensDia",
  props: {
    viagem: {
      type: Object,
    },
    isDiaAtual: {
      type: Boolean,
      default: false,
    },
    mesReferente: {
      type: Number,
      require: true,
    },
    anoReferente: {
      type: Number,
      require: true,
    },
    valorDeCorte: {
      type: Number,
    },
  },
  data() {
    let viagemExibir = this.viagem.viagens_do_dia;

    if (this.isDiaAtual) {
      viagemExibir = viagemExibir
        .filter((valor) => {
          return (
            new Date(valor.data_agendamento_previsto).getHours() >=
            new Date().getHours()
          );
        })
        .sort((a, b) => {
          if (
            parseISO(a.data_agendamento_previsto) >
            parseISO(b.data_agendamento_previsto)
          )
            return 1;
          if (
            parseISO(a.data_agendamento_previsto) <
            parseISO(b.data_agendamento_previsto)
          )
            return -1;
          else return 0;
        })
        .slice(0, this.valorDeCorte);
    } else {
      viagemExibir = viagemExibir
        .sort((a, b) => {
          if (
            parseISO(a.data_agendamento_previsto) >
            parseISO(b.data_agendamento_previsto)
          )
            return 1;
          if (
            parseISO(a.data_agendamento_previsto) <
            parseISO(b.data_agendamento_previsto)
          )
            return -1;
          else return 0;
        })
        .slice(0, this.valorDeCorte);
    }

    return { VIAGEM_LISTA_MODAL, viagemExibir: viagemExibir };
  },
  computed: {},
  components: {
    Iconview,
    IconLocal,
    IconMotorista,
  },
  methods: {
    horaFormatada(data) {
      return format(parseISO(data), "H:mm");
    },
    primeiroNome(nome) {
      if (nome) return nome.split(" ")[0];
      return "";
    },
  },
};
</script>
<style lang="scss" scoped>
.grupo-botao {
  display: grid;
  .lk-dia {
    display: grid;
    justify-content: center;
    span {
      text-align: center;
      font-size: 0.8rem;
      font-style: italic;
      padding: 0 0.5rem;
    }
    a {
      text-align: center;
      display: inline-block;
      color: #014403;
      font-family: "Proxima-Semibold";
      font-size: 0.8rem;
      // border: #014403 solid 2px;
      text-decoration: none;
      padding: 0.3rem 1rem;
      border-radius: 1rem;
      margin-top: 0.5rem;
      svg {
        height: 0.6rem;
        width: 1rem;
        fill: #014403;
      }
    }
    a:hover {
      text-decoration: underline;
    }
  }
}
.sem-viagens{
  margin-top: 2rem;
  display: block;
}
.botao {
  display: grid;
  gap: 0.2rem;
  font-size: 0.9rem;
  cursor: pointer;
  transition: 0.2s;
  padding: 1rem 0.5rem;
  border-radius: 2px;
  .disp-flex {
    display: flex;
    align-items: center;
  }
  span {
    color: #3a3a3a;
    svg {
      height: 1rem;
      width: 1.1rem;
      fill: #3a3a3a;
      margin-bottom: -0.1rem;
    }
  }
  .hora {
    font-family: "Proxima-bold";
    color: bla;
  }
  .motorista {
    font-family: "Proxima-Regular";
  }
  .destino {
    font-family: "Proxima-Regular";
    svg {
      padding-right: 0.2rem;
    }
  }
  .setor {
    font-family: "Proxima-Regular";
    display: block;
    margin-left: 0.2rem;
  }
}
.botao:hover {
  background-color: #04470615;
}
</style>