<template>
  <button type="submit" @click="click" title="Salvar">
    <IconSalvar /><span>Salvar</span>
  </button>
</template>

<script>
import IconSalvar from "../../assets/images/icon-save.svg";
export default {
  name: "BotaoSalvar",
  methods: {
    click() {
      this.$emit("click");
    },
  },
  components: {
    IconSalvar,
  },
};
</script>

<style lang="scss" scoped>

button{
  display: flex;
  align-items: center;
  border: #014403 solid .15rem;
  background-color: transparent;
  padding: .5rem 4rem;
  border-radius: .2rem;
  color: #014403;
  text-decoration: none;
  transition: .2s linear;
  font-size: 1rem;
  margin: 1rem auto 1rem;
  cursor: pointer;
  svg{
    height: 1.3rem;
    width: 1.3rem;
    margin-right: .5rem;
  }
  span{
    font-family: 'Proxima-SemiBold';
  }
}
button:hover{
  background-color: #014403;
  color: white;
}

</style>