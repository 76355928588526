<template>
  <div class="caixa-pesquisa">
    <form @submit="submit">
      <h4>Pesquisar:</h4>
      <div class="item">
        <label>Nome:</label
        ><input
          type="text"
          :value="filtro.nome"
          @input="(evt) => filtroMudou('nome', evt.target.value)"
        />
      </div>
      <div class="botoes-pesquisa">
        <!--<button type="button" @click="limpar">Limpar</button>-->
        <button type="submit"><IconPesquisar /> Pesquisar</button>
      </div>
    </form>
  </div>
</template>

<script>
import IconPesquisar from "../../assets/images/icon-pesquisar.svg";

export default {
  name: "MarcasPesquisaForm",
  components: {
    IconPesquisar,
  },
  model: {
    prop: "filtro",
    event: "update-filtro-pesquisa-marca",
  },
  props: {
    filtro: {
      type: Object,
    },
  },
  methods: {
    submit(event) {
      this.$emit("submit", event);
    },
    filtroMudou(chave, valor) {
      this.$emit("update-filtro-pesquisa-marca", {
        ...this.filtro,
        [chave]: valor,
      });
    },
    limpar() {
      const chaves = Object.keys(this.filtro);
      const filtroLimpo = chaves.reduce((acc, chave) => {
        acc[chave] = null;
        return acc;
      }, {});
      this.$emit("update-filtro-pesquisa-marca", filtroLimpo);
    },
  },
};
</script>
<style lang="scss" scoped>
.caixa-pesquisa {
  background-color: white;
  padding: 1rem;
  h4 {
    margin: 0 0 0.5rem;
    font-family: "Proxima-Bold";
    color: #014403;
    font-size: 1.4rem;
  }
  .item {
    gap: 0.5rem;
    display: grid;
    input {
      height: 1.5rem;
      font-size: 1rem;
    }
  }
  .botoes-pesquisa {
    margin-top: 1rem;
    text-align: center;
    button {
      display: flex;
      border: #014403 solid 0.15rem;
      padding: 0.5rem 2rem;
      border-radius: 1.2rem;
      color: #014403;
      font-family: "Proxima-Semibold";
      text-decoration: none;
      transition: 0.2s linear;
      font-size: 1rem;
      margin: 0 auto;
      cursor: pointer;
      background-color: transparent;
      svg {
        height: 1rem;
        width: 1rem;
        margin-right: 0.5rem;
      }
    }
    button:hover {
      background-color: #014403;
      color: white;
    }
  }
}
</style>