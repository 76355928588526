<template>
  <div class="caixa-pesquisa">
    <form @submit="submit">
      <h4>Pesquisar</h4>
      <div class="row3">
        <div class="item">
          <label>Nome:</label>
          <input
            type="text"
            :value="filtro.nome"
            @input="(evt) => filtroMudou('nome', evt.target.value)"
          />
        </div>
        <div class="item">
          <label>CPF:</label>
          <TheMask
            mask="###.###.###-##"
            type="text"
            :value="filtro.cpf"
            @input="(value) => filtroMudou('cpf', value)"
          />
        </div>
        <div class="item">
          <label>CNH:</label>
          <input
            type="text"
            :value="filtro.cnh"
            @input="(evt) => filtroMudou('cnh', evt.target.value)"
          />
        </div>
      </div>
      <div class="botoes-pesquisa">
        <button class="bt-limpar" type="button" @click="limpar">
          <IconLimpar /> Limpar
        </button>
        <button class="bt-pesquisar" type="submit">
          <IconPesquisar /> Pesquisar
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import IconPesquisar from "../../assets/images/icon-pesquisar.svg";
import IconLimpar from "../../assets/images/icon-clean.svg";

import { TheMask } from "vue-the-mask";

export default {
  name: "MotoristasPesquisaForm",
  model: {
    prop: "filtro",
    event: "update-filtro-pesquisa-motorista",
  },
  props: {
    filtro: {
      type: Object,
    },
  },
  components: {
    TheMask,
    IconPesquisar,
    IconLimpar,
  },
  methods: {
    submit(event) {
      this.$emit("submit", event);
    },
    filtroMudou(chave, valor) {
      this.$emit("update-filtro-pesquisa-motorista", {
        ...this.filtro,
        [chave]: valor,
      });
    },
    limpar() {
      const chaves = Object.keys(this.filtro);
      const filtroLimpo = chaves.reduce((acc, chave) => {
        acc[chave] = null;
        return acc;
      }, {});
      this.$emit("update-filtro-pesquisa-motorista", filtroLimpo);
    },
  },
};
</script>
<style lang="scss" scoped>
.caixa-pesquisa {
  background-color: white;
  padding: 1rem;
  h4 {
    margin: 0 0 0.5rem;
    font-family: "Proxima-Bold";
    color: #014403;
    font-size: 1.4rem;
  }
  .item {
    display: grid;
    gap: 0.5rem;
    margin: 1rem 0;
    input {
      height: 1.5rem;
      font-size: 1rem;
    }
    select {
      height: 2rem;
    }
  }
  .botoes-pesquisa {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .bt-limpar {
      color: black;
      font-size: 1rem;
      background-color: transparent;
      font-family: "Proxima-Semibold";
      border: none;
      margin: 0 2rem;
      cursor: pointer;
      transition: 0.2s;
      svg {
        height: 1rem;
        width: 1rem;
        margin-right: 0.5rem;
      }
    }
    .bt-pesquisar {
      display: flex;
      border: #014403 solid 0.15rem;
      padding: 0.5rem 2rem;
      border-radius: 1.2rem;
      color: #014403;
      font-family: "Proxima-Semibold";
      text-decoration: none;
      transition: 0.2s linear;
      font-size: 1rem;
      cursor: pointer;
      background-color: transparent;
      svg {
        height: 1rem;
        width: 1rem;
        margin-right: 0.5rem;
      }
    }
    .bt-pesquisar:hover {
      background-color: #014403;
      color: white;
    }
  }
}
@media screen and(min-width: '50em') {
  .row3 {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    gap: 1rem;
  }
}
</style>