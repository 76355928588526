<template>
  <div class="centro">
    <div class="parte1">
      <div class="esquerda">
        <router-link :to="{ name: MOTORISTAS, query: { ...$route.query } }"
          ><IconVoltar
        /></router-link>
        <h1>{{ $route.params.id ? "Editar" : "Novo" }} Motorista</h1>
      </div>
      <BotaoVisualizarForm
        v-if="$route.params.id"
        :to="{ name: MOTORISTA_SHOW, params: { id: $route.params.id } }"
      />
    </div>
    <div v-if="carregando && !erroCarregar">
      <AppLoading />
    </div>
    <div id="erro-container">
      <AppErrosFormularios
        v-if="erroSalvar && erroSalvar.length > 0"
        :erros="erroSalvar"
      />
      <AppErro v-if="erroCarregar && !carregando"></AppErro>
    </div>
    <form
      class="caixa-form"
      @submit.prevent="salvar"
      v-if="!carregando && !erroCarregar"
    >
      <div class="row2-2">
        <div class="item">
          <label>Nome:<span>*</span> </label>
          <input type="text" v-model="motorista.nome" />
        </div>
        <div class="item">
          <label>CPF:<span>*</span> </label>
          <TheMask type="text" mask="###.###.###-##" v-model="motorista.cpf" />
        </div>
      </div>
      <div class="row4">
        <div class="item">
          <label>Tipo da CNH:<span>*</span> </label>
          <select v-model="motorista.tipo_cnh">
            <option disabled :value="null">Selecione</option>
            <option
              v-for="tipo in tiposCnh"
              :key="tipo.value"
              :value="tipo.value"
            >
              {{ tipo.label }}
            </option>
          </select>
        </div>
        <div class="item">
          <label> Data de Vencimento da CNH: <span>*</span> </label>
          <DatePicker
            v-model="motorista.data_vencimento_carteira_habilitacao"
            format="DD/MM/YYYY"
          />
        </div>
        <div class="item">
          <label> CNH: <span>*</span> </label>
          <input type="text" v-model="motorista.cnh" />
        </div>
        <div class="item">
          <div class="checkbox">
            <label class="check path" for="ativo">
              <input type="checkbox" v-model="motorista.ativo" id="ativo" />
              <svg viewBox="0 0 21 21">
                <path
                  d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"
                ></path>
              </svg>
              <span>Ativo?</span>
            </label>
          </div>
        </div>
      </div>
      <div>
        <BotaoSalvar v-if="!salvando" />
        <AppLoading v-else size="small" texto="" />
      </div>
    </form>
  </div>
</template>
<script>
import { TheMask } from "vue-the-mask";
import MotoristaApi from "../../services/api/MotoristaApi";
import AppLoading from "../../components/AppLoading";
import AppErrosFormularios from "../../components/AppErrosFormularios";
import AppErro from "../../components/AppErro";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import IconVoltar from "../../assets/images/icon-voltar.svg";
import BotaoSalvar from "../../components/botoes/BotaoSalvar.vue";

import {
  MOTORISTAS,
  MOTORISTA_SHOW,
  MOTORISTA_EDIT,
} from "../../router/routeNames";
import BotaoVisualizarForm from "../../components/botoes/BotaoVisualizarForm";
import { parseISO } from "date-fns";

export default {
  name: "MotoristaForm",
  data() {
    return {
      MOTORISTAS,
      MOTORISTA_SHOW,
      MOTORISTA_EDIT,
      motorista: {
        id: null,
        nome: "",
        tipo_cnh: null,
        data_vencimento_carteira_habilitacao: null,
        ativo: true,
        cpf: null,
        cnh: "",
      },
      carregando: false,
      tiposCnh: [],
      salvando: false,
      erroCarregar: false,
      erroSalvar: null,
    };
  },
  created() {
    this.motoristaApi = new MotoristaApi();
    this.carregarTiposCnh();
    if (this.$route.params.id) {
      this.carregarMotorista();
    }
  },
  components: {
    AppLoading,
    AppErrosFormularios,
    AppErro,
    TheMask,
    BotaoVisualizarForm,
    DatePicker,
    IconVoltar,
    BotaoSalvar,
  },
  methods: {
    async carregarTiposCnh() {
      try {
        this.erroCarregar = false;
        this.carregando = true;

        this.tiposCnh = await this.motoristaApi.getTipoCnh();

        this.carregando = false;
      } catch (error) {
        console.log(error);
        this.carregando = false;
        this.erroCarregar = true;
      }
    },
    async carregarMotorista() {
      try {
        this.carregando = true;
        this.erroCarregar = false;

        const data = await this.motoristaApi.getMotorista(
          this.$route.params.id
        );

        this.motorista = data;

        if (this.motorista.data_vencimento_carteira_habilitacao) {
          this.motorista.data_vencimento_carteira_habilitacao = parseISO(
            data.data_vencimento_carteira_habilitacao
          );
        }

        this.carregando = false;
      } catch (err) {
        console.log(err);
        this.carregando = false;
        this.erroCarregar = true;
      }
    },
    async salvar() {
      try {
        this.salvando = true;
        this.erroSalvar = null;

        const data = await this.motoristaApi.salvarMotorista({
          id: this.motorista.id,
          cpf: this.motorista.cpf,
          nome: this.motorista.nome,
          tipo_cnh: this.motorista.tipo_cnh,
          data_vencimento_carteira_habilitacao:
            this.motorista.data_vencimento_carteira_habilitacao,
          ativo: this.motorista.ativo,
          cnh: this.motorista.cnh,
        });

        this.$emit("on-reload-data");
        this.salvando = false;

        this.$swal({
          icon: "success",
          position: "top-end",
          title: "Motorista salvo com sucesso!",
          timer: 3000,
          toast: true,
          showConfirmButton: false,
        });

        this.$router.push({
          name: MOTORISTA_SHOW,
          params: { id: data.id },
        });
      } catch (err) {
        this.salvando = false;

        let data;
        if (err.response) {
          data = err.response.data;
        }

        if (data) {
          if (Array.isArray(data)) {
            this.erroSalvar = data;
          } else if (data.mensagem) {
            this.erroSalvar = [data.mensagem];
          } else {
            this.erroSalvar = [
              "Houve um erro ao salvar os dados. Por favor, tente novamente",
            ];
          }
        } else {
          this.erroSalvar = [
            "Houve um erro ao salvar os dados. Por favor, tente novamente",
          ];
        }
        this.$scrollTo("#erro-container", {
          offset: -200,
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.parte1 {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  .esquerda {
    display: flex;
    align-items: center;
    gap: 1rem;
    h1 {
      font-family: "Proxima-Bold";
      color: #014403;
      margin: 0;
    }
  }
}
.caixa-form {
  background-color: white;
  padding: 1rem;
  margin-bottom: 3rem;
  .item {
    display: grid;
    margin: 1rem 0;
    gap: 0.5rem;
    label {
      font-family: "Proxima-Regular";
      margin-bottom: 0.2rem;
    }
    span {
      color: red;
    }
    input {
      height: 1.5rem;
      width: 100%;
      font-size: 1rem;
      width: calc(100% - 0.5rem);
    }
    select {
      height: 2rem;
      font-size: 1rem;
    }
    .mx-datepicker {
      width: 100%;
    }

    .checkbox {
      margin-top: 1rem;
      input {
        width: auto;
      }
      span {
        color: black;
        font-family: "Proxima-SemiBold";
      }
      .check {
        --background: #fff;
        --border: #d1d6ee;
        --border-hover: #bbc1e1;
        --border-active: #1b5138;
        --tick: #fff;
        position: relative;
        cursor: pointer;
        input,
        svg {
          width: 21px;
          height: 21px;
          display: inline-block;
        }
        input {
          -webkit-appearance: none;
          -moz-appearance: none;
          position: relative;
          outline: none;
          background: var(--background);
          border: none;
          margin: 0;
          transform: translateY(5px);
          padding: 0;
          cursor: pointer;
          border-radius: 4px;
          transition: box-shadow 0.3s;
          box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));
          &:hover {
            --s: 2px;
            --b: var(--border-hover);
          }
          &:checked {
            --b: var(--border-active);
          }
        }
        svg {
          pointer-events: none;
          fill: none;
          stroke-width: 2px;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke: var(--stroke, var(--border-active));
          position: absolute;
          top: 0;
          left: 0;
          width: 21px;
          height: 21px;
          transform: scale(var(--scale, 1)) translateZ(1);
          margin-top: -0.2rem;
        }
        span {
          display: inline;
          margin-left: 1rem;
        }
        &.path {
          input {
            &:checked {
              --s: 2px;
              transition-delay: 0.4s;
              & + svg {
                --a: 16.1 86.12;
                --o: 102.22;
              }
            }
          }
          svg {
            stroke-dasharray: var(--a, 86.12);
            stroke-dashoffset: var(--o, 86.12);
            transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
          }
        }
      }
    }
  }
}
@media screen and (min-width: "50em") {
  .row2-2 {
    display: grid;
    grid-template-columns: 4fr 1fr;
    gap: 1rem;
    align-items: center;
  }
  .row4 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1rem;
    align-items: center;
  }
}
</style>
