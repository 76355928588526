import * as RouteNames from "../routeNames";
import MarcaIndex from "../../views/marca/MarcaIndex";
import MarcaForm from "../../views/marca/MarcaForm";

//raiz indica se é uma rota que pode ser a inicial para o usuario que logar (normalmente vao ser so os indexes de cada crud)
//colocar as rotas em ordem de prioridade para login (as mais no topo vao ser a tela inicial do usuario se ele tiver permissao)

const marcas = [
  {
    name: RouteNames.MARCAS,
    component: MarcaIndex,
    path: "/marcas",
    meta:{
      requerPermissao: true,
      raiz: true,
      permissoes: ["marcas"],
    },
    children:[
      {
        name: RouteNames.MARCA_EDIT,
        component: MarcaForm,
        path: "/marcas/:id/edit",
        meta:{
          requerPermissao: true,
          permissoes: ["marcas"],
        },
      },
      {
        name: RouteNames.MARCA_NEW,
        component: MarcaForm,
        path: "/marcas/new",
        meta:{
          requerPermissao: true,
          permissoes: ["marcas"],
        },
      },
    ]
  },
];

export default marcas;